enum VALIDATION_ENUM {
  REQUIRED = "To pole jest wymagane",
  EMPTY_SPACE = "To pole nie może zawierać spacji",
  WRONG_EMAIL = "Niepoprawny adres email",
  MIN_8 = "Minimum 8 znaków",
  CONFIRM_PASSWORD = "Hasła muszą być takie same",
  MIN_9 = "Minimum 9 znaków",
  MAX_12 = "Maksymalnie 12 znaków",
  CANNOT_START_AND_END_WITH_SPACE = "Nie może zaczynać się ani kończyć spacją",
  MIN3 = "Minimum 3 znaki",
  MIN_2 = "Minimum 2 znaki",
  NUMBER = "To pole może zawierać wyłącznie cyfry",
  PASSWORD_MUST_BE_DIFFERENT = "Nowe hasło musi być różne od starego",
  
}

export default VALIDATION_ENUM;
