import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useStoreCompanyClient, useUpdateCompanyClient } from "hooks/companies";
import { useGetCountriesCurrency } from "hooks/countries";
import { useGetIndustriesHook } from "hooks/industries";
import moment from "moment";
// import { useAddAdmin, useUpdateUser } from "hooks/users";
import {
  Button,
  CalendarInput,
  DropFileInput,
  FullscreenModal,
  Input,
  SelectComponent,
} from "shared";
import { CompanyMyType, CompanyStoreClientType } from "types/CompanyTypes";
import { getCurrencyFromUuid, getCurrencySymbol } from "utils";
import * as Yup from "yup";

interface AddCompanyClientModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  company?: CompanyMyType | null;
}

const AddCompanyClientModal: React.FC<AddCompanyClientModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  company,
}) => {
  const { mutateAsync: storeCompany } = useStoreCompanyClient();
  const { mutateAsync: updateCompany } = useUpdateCompanyClient();
  const { data: industries } = useGetIndustriesHook();
  const { data: currencyOptions } = useGetCountriesCurrency();

  const initialValues = {
    name: company?.name || "",
    industryUuid: company?.industryUuid || "",
    currencyUuid: company?.currencyUuid || "",
    sharesCount: company?.sharesCount || null,
    sharesValue: company?.sharesValue || null,
    invoice: null,
    completedAt:
      company?.firstTransaction?.completedAt || moment().format("YYYY-MM-DD"),
  };

  const onSubmit = async (values: CompanyStoreClientType) => {
    !!company
      ? await updateCompany(
          { ...company, ...values, shareUuid: company.sharesUuid },
          {
            onSuccess: () => {
              onSuccess && onSuccess();
              onClose();
            },
          }
        )
      : await storeCompany(
          { ...values },
          {
            onSuccess: () => {
              onSuccess && onSuccess();
              onClose();
            },
          }
        );
    onClose();
  };

  const validation = () =>
    Yup.object().shape({
      name: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .max(50)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_ENUM.CANNOT_START_AND_END_WITH_SPACE
        ),
      industryUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      currencyUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      sharesCount: Yup.number().required(VALIDATION_ENUM.REQUIRED),
      completedAt: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">
        {!!company ? "Edycja spółki" : "Dodaj spółkę"}
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
      >
        {({ errors, values, handleSubmit }) => (
          <Form className="m-width-100 fullscreen-modal__select-margin">
            <Field
              type="text"
              id="name"
              name="name"
              label={"Nazwa spółki"}
              as={Input}
              maxLength={100}
            />

            <Field
              label="Branża"
              name="industryUuid"
              id="industryUuid"
              as={SelectComponent}
              options={industries}
            />
            <Field
              label="Waluta"
              name="currencyUuid"
              id="currencyUuid"
              as={SelectComponent}
              options={currencyOptions}
              isSearchable
              className={"input__wrapper"}
            />
            <Field
              type="number"
              id="sharesCount"
              name="sharesCount"
              label={"Ilość udziałów"}
              as={Input}
            />
            <div className="input--currency">
              <Field
                type="number"
                id="sharesValue"
                name="sharesValue"
                label={"Wartość udziałów (opcjonalne)"}
                as={Input}
              />
              <span>
                {getCurrencySymbol(getCurrencyFromUuid(values.currencyUuid))}
              </span>
            </div>

            <Field
              type="date"
              id="completedAt"
              name="completedAt"
              label={"Data pierwszej transakcji"}
              component={CalendarInput}
              errors={errors}
            />

            <Field
              label={
                <>
                  Umowa <span>(opcjonalne)</span>
                </>
              }
              name="invoice"
              required
              id="invoice"
              as={DropFileInput}
              accept=".pdf"
            />

            <div className="fullscreen-modal__buttons">
              <Button
                label="Anuluj"
                onClick={onClose}
                className="button--stroke button--rounded"
              />
              <Button
                label={"Potwierdź"}
                type="submit"
                onClick={() => null}
                className=" button--rounded button--black"
              />
            </div>
          </Form>
        )}
      </Formik>
    </FullscreenModal>
  );
};

export default AddCompanyClientModal;
