import { Field, Form, Formik } from "formik";
import { useSendContactMessage } from "hooks/contact";
import { useRef } from "react";
import { Button, Checkbox, Input, Textarea } from "shared";
import * as Yup from "yup";

type ContactFormProps = {};

const ContactForm: React.FC<ContactFormProps> = () => {
  const { mutateAsync: sendMessage } = useSendContactMessage();
  const formRef = useRef<any>(null);
  const onSubmit = (values: any) => {
    sendMessage(values, {
      onSuccess: () => {
        formRef.current.resetForm();
      },
    });
  };
  const initialValues = {
    name: "",
    email: "",
    message: "",
    acceptance: false,
  };

  const validation = () =>
    Yup.object().shape({
      name: Yup.string().required("Pole wymagane"),
      email: Yup.string()
        .email("Niepoprawny adres email")
        .required("Pole wymagane"),
        message: Yup.string().required("Pole wymagane"),
      acceptance: Yup.boolean().oneOf([true], "Pole wymagane"),
    });
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: any) => onSubmit(values)}
      validationSchema={validation()}
      enableReinitialize
      innerRef={formRef}
    >
      {({ errors, values, handleSubmit }) => (
        <Form className="contact__form">
          <h3>Formularz kontaktowy</h3>
          <div className="contact__form-row">
            <Field
              type="text"
              id="name"
              name="name"
              label={"Imię"}
              as={Input}
            />
            <Field
              type="email"
              id="email"
              name="email"
              label={"Email"}
              as={Input}
            />
          </div>

          <Field
            type="textarea"
            id="message"
            name="message"
            label={"Wiadomość"}
            component={Textarea}
            maxLength={1000}
          />
          <Field
            id="acceptance"
            name="acceptance"
            wrapperClassName="login-wrapper__acceptance"
            label={
              <p>
                Zgadzam się na warunki umieszczone w regulaminie aplikacji i
                Polityce prywatności.
              </p>
            }
            as={Checkbox}
          />
          <Button
            label={"Wyślij"}
            type="submit"
            styleType="primary"
            size="large"
            onClick={() => null}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
