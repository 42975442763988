import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { SharesSummaryType } from "types/SharesTypes";

export default function useGetSharesSummary(enabled = true) {
  return useQuery({
    queryKey: ["shares/company"],
    queryFn: async () => {
      return axiosInstance.get(`/shares/summary`).then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: SharesSummaryType) => data,
    retry: false,
    enabled,
    staleTime: 1000 * 60 * 5,
  });
}
