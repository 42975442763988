import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useGetPhonePrefixes } from "hooks/phone";
import { useUpdatePersonalData } from "hooks/users";
import { useEffect, useRef } from "react";
import {
  Button,
  FullscreenModal,
  Input,
  PhoneWithPrefix,
  SelectComponent,
} from "shared";
import { UserType } from "types/UsersTypes";
import * as Yup from "yup";

interface EditPersonalDataModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  user: UserType | null;
}

const EditPersonalDataModal: React.FC<EditPersonalDataModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  user,
}) => {
  const { data: phonePrefixes } = useGetPhonePrefixes();
  const { mutateAsync: updatePersonalData } = useUpdatePersonalData();
  const formRef = useRef<any>(null);

  const initialValues = {
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    phonePrefix: user?.phonePrefixUuid || "",
  };

  useEffect(() => {
    if (phonePrefixes && formRef.current) {
      const phonePrefix = phonePrefixes.find(
        (prefix) => prefix.value === user?.phonePrefixUuid
      );
      formRef.current.setFieldValue(
        "phonePrefix",
        phonePrefix || phonePrefixes[0].value
      );
    }
  }, [phonePrefixes, user]);

  const onSubmit = async (values: typeof initialValues) => {
    await updatePersonalData(
      {
        email: values.email,
        phoneNumber: values.phoneNumber,
        phonePrefixUuid: values.phonePrefix,
        uuid: user?.uuid,
      },
      {
        onSuccess: () => {
          onSuccess && onSuccess();
          onClose();
        },
      }
    );

    onClose();
  };

  const validation = () =>
    Yup.object().shape({
      phoneNumber: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .min(9, VALIDATION_ENUM.MIN_9)
        .max(12, VALIDATION_ENUM.MAX_12)
        .test("is-digitals-only", VALIDATION_ENUM.NUMBER, (value) => {
          return /^\d+$/.test(value);
        }),

      phonePrefix: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      email: Yup.string()
        .email(VALIDATION_ENUM.WRONG_EMAIL)
        .required(VALIDATION_ENUM.REQUIRED),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">Edycja danych osobowych</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
        innerRef={formRef}
      >
        {({ errors, values, handleSubmit }) => (
          <Form className="m-width-100">
            <PhoneWithPrefix>
              <Field
                label="Telefon"
                name="phonePrefix"
                id="phonePrefix"
                as={SelectComponent}
                options={phonePrefixes}
              />
              <Field
                name="phoneNumber"
                type="text"
                id="phoneNumber"
                maxLength="12"
                as={Input}
              />
            </PhoneWithPrefix>

            <Field
              type="text"
              id="email"
              name="email"
              label={"Email"}
              as={Input}
            />

            <div className="fullscreen-modal__buttons">
              <Button
                label="Anuluj"
                onClick={onClose}
                className="button--stroke button--rounded"
              />
              <Button
                label={"Zapisz zmiany"}
                type="submit"
                onClick={() => null}
                className=" button--rounded button--black"
              />
            </div>
          </Form>
        )}
      </Formik>
    </FullscreenModal>
  );
};

export default EditPersonalDataModal;
