enum PACKET_ENUM {
  BASIC = "basic",
  STANDARD = "standard",
  PREMIUM = "premium",
  PRO = "pro",
}

enum PACKET_PRICE_ENUM {
  BASIC = 9,
  STANDARD = 19,
  PREMIUM = 29,
  PRO = 999,
}

export { PACKET_ENUM, PACKET_PRICE_ENUM };
