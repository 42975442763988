import { CountriesType } from "types/CountriesTypes";
import { queryClient } from "App";
const getIndustryFromUuid = (uuid: string): string => {
  const industries = queryClient.getQueryData<[CountriesType]>([
    "industries",
  ]);
  const currency =
  industries &&
  industries.find((item) => item.value === uuid);
  return currency ? currency.label : "";
};

export default getIndustryFromUuid;
