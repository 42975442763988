import { PhonePrefixType } from "types/CountriesTypes";

const getPrefixFromUuid = (
  uuid: string,
  prefixes: PhonePrefixType[] | []
): string => {
  const prefix = prefixes.find((prefix) => prefix.value === uuid);
  return prefix ? prefix.label : "";
};

export default getPrefixFromUuid;