import SVG from "shared/SVG";
import "./TopNavigation.scss";
import { ROUTE_ENUM, SVG_ENUM } from "enums";
import Button from "shared/Button/Button";
import { useNavigate } from "react-router";

type TopNavigationProps = {};

const TopNavigation: React.FC<TopNavigationProps> = () => {
  const navigate = useNavigate();
  return (
    <header className="top-navigation">
      <div className="top-navigation__container">
        <div className="layout">
          <div className="top-navigation__wrapper">
            <div
              className="top-navigation__left"
              onClick={() => navigate(ROUTE_ENUM.HOME)}
            >
              <SVG type={SVG_ENUM.LOGO} />
            </div>
            <nav className="top-navigation__right">
              <span className="top-navigation__item">CENNIK</span>
              <span className="top-navigation__item">PRZYKŁAD</span>
              <Button
                size="small"
                styleType="strokeBlack"
                label="Zaloguj się"
                onClick={() => navigate(ROUTE_ENUM.LOGIN)}
              />
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopNavigation;
