import { SVG_ENUM } from "enums";
import { FieldAttributes, useField } from "formik";
import { SVG } from "shared";
import "./Checkbox.scss";

interface CheckboxProps extends FieldAttributes<any> {
  name: string;
  label: string;
  className?: string;
}

function Checkbox({
  errors = {},
  label,
  className = "",
  wrapperClassName = "",
  ...props
}: CheckboxProps) {
  const [field, meta] = useField({
    ...props,
    name: props.name,
    type: "checkbox",
  });

  const handleOnClick = () => {
    field.onChange({
      target: {
        name: field.name,
        value: !field.value,
        checked: !field.value,
        type: "checkbox",
      },
    });
  };

  return (
    <div className={`checkbox ${wrapperClassName}`}>
      <label className="checkbox__label">
        <input
          type="checkbox"
          {...field}
          {...props}
          onChange={() => handleOnClick()}
        />
        <span
          className={`checkbox__dummy-input ${
            !!meta.value ? "checkbox__dummy-input--checked" : ""
          }`}
        >
          <SVG
            className={`checkbox__svg ${
              !!meta.value ? "checkbox__svg--checked" : ""
            }`}
            type={SVG_ENUM.CHECKED}
          />
        </span>
        {label ? label : null}
      </label>

      {meta.touched && meta.error && (
        <div className="input__error">
          <span>{meta.error}</span>
        </div>
      )}
    </div>
  );
}

export default Checkbox;
