import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosConfig from "axiosConfig";
import { AdminRegisterType } from "types/AuthTypes";
import { TOAST_TYPES_ENUM } from "enums";
import { Toast } from "shared";

export const useAdminRegister = (
  options?: UseMutationOptions<any, Error, AdminRegisterType>
) => {
  return useMutation({
    mutationKey: ["register-admin"],
    mutationFn: async (credentials: AdminRegisterType) => {
      return axiosConfig.put(`/auth/activate`, credentials).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Ładowanie...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "register-admin",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Zarejestrowano pomyślnie",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "register-admin",
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },

    onError: (error, variables, context) => {
      Toast({
        message: "Błąd rejestracji",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "register-admin",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
