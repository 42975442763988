import React, { useEffect, useRef, useState } from "react";
import { Button, Input, LoginWrapper, SVG } from "shared";
import "./LoginAdminPage.scss";
import { Field, Formik, Form } from "formik";
import { ROUTE_ENUM, SVG_ENUM, VALIDATION_ENUM } from "enums";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useGetPhonePrefixes } from "hooks/phone";
import { useGetMe, useLogin } from "hooks/auth";
import { checkIfLogged } from "utils";

type LoginAdminPageProps = {};

const LoginAdminPage: React.FC<LoginAdminPageProps> = () => {
  const { data: phonePrefixes } = useGetPhonePrefixes();
  const navigate = useNavigate();
  const formRef = useRef<any>(null);
  const [showError, setShowError] = useState(false);
  const { data: authorizedUser } = useGetMe();
  const { mutate } = useLogin({
    onSuccess: () => {
      if (sessionStorage.getItem("destinationPath")) {
        navigate(sessionStorage.getItem("destinationPath") as string);
        sessionStorage.removeItem("destinationPath");
      } else {
        navigate(ROUTE_ENUM.USERS);
      }
    },
    onError: (error: Error) => {
      setShowError(true);
    },
  });

  const validation = () =>
    Yup.object().shape({
      email: Yup.string()
        .email(VALIDATION_ENUM.WRONG_EMAIL)
        .required(VALIDATION_ENUM.REQUIRED),
      password: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .min(8, VALIDATION_ENUM.MIN_8),
    });

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values: typeof initialValues) => {
    mutate(values);
  };

  useEffect(() => {
    if (authorizedUser) {
      checkIfLogged(authorizedUser, navigate);
    }
  }, [authorizedUser, navigate]);

  useEffect(() => {
    if (phonePrefixes && formRef.current) {
      formRef.current.setFieldValue("phonePrefixUuid", phonePrefixes[0]?.value);
    }
  }, [phonePrefixes]);

  return (
    <LoginWrapper className="login-admin">
      <h1 className="login-wrapper__header">Zaloguj się</h1>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
        innerRef={formRef}
      >
        {({ errors, values, handleSubmit, setFieldValue }) => (
          <Form>
            <p
              className={`login-admin__error ${
                showError ? "login-admin__error--show" : ""
              }`}
            >
              Niepoprawne dane logowania
              <SVG type={SVG_ENUM.CLOSE_CIRCLE} />
            </p>
            <Field
              type="text"
              id="email"
              name="email"
              label={"E-mail"}
              as={Input}
            />{" "}
            <Field
              type="password"
              id="password"
              name="password"
              label={"Hasło"}
              as={Input}
            />
            <p
              onClick={() => navigate(ROUTE_ENUM.RESET_PASSWORD)}
              className="login-admin__dont-remember"
            >
              Nie pamiętasz hasła?
            </p>
            <Button
              label="Zaloguj sie"
              className="button--black button--rounded login-wrapper__submit button--wide"
              onClick={() => {
                handleSubmit();
              }}
            />
          </Form>
        )}
      </Formik>
    </LoginWrapper>
  );
};

export default LoginAdminPage;
