import { SVG } from "shared";
import "./Contact.scss";
import ContactForm from "./ContactForm";
import { SVG_ENUM } from "enums";

type ContactProps = {};

const Contact: React.FC<ContactProps> = () => {
  return (
    <div className="contact">
      <SVG type={SVG_ENUM.LOGIN_BG_DESKTOP} className="contact__bg" />
      <div className="layout">
        <div className="contact__left">
          <h2>
            Bądź <i>z nami</i> w kontakcie
          </h2>
          <p>
            Nasza aplikacja została zaprojektowana przez ekspertów w dziedzinie
            inwestycji, którzy mają wieloletnie doświadczenie na rynku. Dzięki
            nowoczesnym technologiom i zaawansowanym narzędziom analitycznym,
            możemy zapewnić naszym klientom najnowsze i najbardziej precyzyjne
            informacje.
          </p>
        </div>
        <div className="contact__right">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contact;
