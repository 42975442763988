import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useStoreShare } from "hooks/shares";
import { useGetClientsOptions } from "hooks/users";
import moment from "moment";
import {
  Button,
  CalendarInput,
  DropFileInput,
  FullscreenModal,
  Input,
  SelectComponent,
} from "shared";
import { CompanyType } from "types/CompanyTypes";
import { getCurrencyFromUuid, getCurrencySymbol } from "utils";
import * as Yup from "yup";

interface AddSharesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  company?: CompanyType | null;
}

const AddSharesModal: React.FC<AddSharesModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  company,
}) => {
  const { mutateAsync: storeShare } = useStoreShare();
  const { data: clientOptions } = useGetClientsOptions(isOpen);

  const initialValues = {
    userUuid: "",
    companyUuid: company?.uuid ? company.uuid : "",
    sharesCount: 0,
    sharesValue: 0,
    invoice: null,
    completedAt: moment().format("YYYY-MM-DD"),
  };

  const onSubmit = async (values: typeof initialValues | any) => {
    await storeShare(values, {
      onSuccess: () => {
        onSuccess && onSuccess();
        onClose();
      },
    });
    onClose();
  };

  const validation = () =>
    Yup.object().shape({
      userUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      sharesCount: Yup.number().required(VALIDATION_ENUM.REQUIRED),
      // sharesValue: Yup.number().required(VALIDATION_ENUM.REQUIRED),
      invoice: Yup.mixed().required(VALIDATION_ENUM.REQUIRED),
      completedAt: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">
        Dodawanie klienta <i>{company?.name}</i>
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
      >
        {({ errors, values, handleSubmit }) => (
          <Form className="m-width-100">
            <Field
              label="Klient"
              name="userUuid"
              id="userUuid"
              as={SelectComponent}
              options={clientOptions}
              isSearchable
              className={"input__wrapper"}
            />

            <Field
              type="number"
              id="sharesCount"
              name="sharesCount"
              label={"Ilość udziałów"}
              as={Input}
            />

            <div className="input--currency">
              <Field
                type="number"
                id="sharesValue"
                name="sharesValue"
                label={"Wartość udziałów (opcjonalne)"}
                maxLength={10}
                as={Input}
              />
              <span>
                {company &&
                  getCurrencySymbol(getCurrencyFromUuid(company?.currencyUuid))}
              </span>
            </div>
            <div className="input--currency"></div>
            <Field
              type="date"
              id="completedAt"
              name="completedAt"
              label={"Data transakcji"}
              component={CalendarInput}
              errors={errors}
            />

            <Field
              label="Umowa"
              name="invoice"
              required
              id="invoice"
              as={DropFileInput}
              accept=".pdf"
            />

            <div className="fullscreen-modal__buttons">
              <Button
                label="Anuluj"
                onClick={onClose}
                className="button--stroke button--rounded"
              />
              <Button
                label={"Dodaj"}
                type="submit"
                onClick={() => null}
                className=" button--rounded button--black"
              />
            </div>
          </Form>
        )}
      </Formik>
    </FullscreenModal>
  );
};

export default AddSharesModal;
