import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { IndustryOptionsType } from "types/IndustryTypes";

export default function useGetIndustriesHook() {
  return useQuery({
    queryKey: ["industries"],

    queryFn: async () => {
      return axiosInstance.get(`industries`).then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: IndustryOptionsType[]) => data,
  });
}
