import { createPortal } from "react-dom";
import "./DashboardTopPortal.scss";
import { useEffect, useState } from "react";

interface DashboardTopBarType {
  children: React.ReactNode;
}

const DashboardTopPortal = ({ children }: DashboardTopBarType) => {
  const [topBar, setTopBar] = useState<Element | null>(null);
  useEffect(() => {
    const foundTopBar = document.querySelector(".dtb");
    setTopBar(foundTopBar);
  }, []);

  return <>{topBar && createPortal(children, topBar as Element)}</>;
};

export default DashboardTopPortal;
