import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { CountriesType } from "types/CountriesTypes";

export default function useGetCountries() {
  return useQuery({
    queryKey: ["countries"],

    queryFn: async () => {
      return axiosInstance.get(`countries`).then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: CountriesType[]) => data,
    staleTime: 1000 * 60 * 60 * 24,
  });
}
