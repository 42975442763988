import React from "react";
import "./EnterCode.scss";

type EnterCodeType = {
  code: string[];
  setCode: (code: string[]) => void;
};

const EnterCode: React.FC<EnterCodeType> = ({ code, setCode }) => {
  const handleChange = (value: string, index: number) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value !== "" && index < 3) {
        (
          document.getElementById(`code-input-${index + 1}`) as HTMLInputElement
        ).focus();
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      (
        document.getElementById(`code-input-${index - 1}`) as HTMLInputElement
      ).focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("Text");
    if (/^\d{4}$/.test(pasteData)) {
      const newCode = pasteData.split("");
      setCode(newCode);
      (document.getElementById(`code-input-3`) as HTMLInputElement).focus();
    }
  };

  return (
    <div className="enter-code">
      {code.map((digit, index) => (
        <input
          key={index}
          id={`code-input-${index}`}
          type="number"
          maxLength={1}
          value={digit}
          onChange={(e) => handleChange(e.target.value, index)}
          className="code-input"
          onPaste={index === 0 ? handlePaste : undefined}
          onKeyDown={(e) => handleKeyDown(e, index)}
        />
      ))}
      {/* <div className="input__error">Pole wymagane</div> */}
    </div>
  );
};

export default EnterCode;
