import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { SharesByCompanyType } from "types/SharesTypes";

export default function useGetSharesByCompany(uuid: string) {
  return useQuery({
    queryKey: ["shares/company", uuid],
    queryFn: async () => {
      return axiosInstance
        .get(`/shares/company/${uuid}`)
        .then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: SharesByCompanyType[]) => data,
    retry: false,
    enabled: !!uuid,
    staleTime: 1000 * 60 * 5,
  });
}
