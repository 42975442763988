import { PACKET_ENUM, SVG_ENUM } from "enums";
import { PACKET_PRICE_ENUM } from "enums/PacketsEnum";
import moment from "moment";
import React from "react";
import { createPortal } from "react-dom";
import { Button, SVG } from "shared";
import { ParentType } from "../../PacketModal";
interface SinglePacketProps {
  packetName: PACKET_ENUM;
  currentPacket: PACKET_ENUM;
  selectedPacket: PACKET_ENUM | null;
  setSelectedPacket: (packet: PACKET_ENUM | null) => void;
  parent: ParentType;
}

const SinglePacket: React.FC<SinglePacketProps> = ({
  packetName,
  currentPacket,
  selectedPacket,
  setSelectedPacket,
  parent,
}) => {
  if (!packetName) return null;
  const packetNameLarge: any = packetName.toUpperCase();
  const packetPrice = PACKET_PRICE_ENUM[packetNameLarge];

  const isMoreThanBasic = packetName !== PACKET_ENUM.BASIC;
  const isMoreThanStandard =
    packetName !== PACKET_ENUM.STANDARD && isMoreThanBasic;
  const isMoreThanPremium =
    packetName !== PACKET_ENUM.PREMIUM && isMoreThanStandard;

  const isActive = currentPacket === packetName;
  const isSelected = selectedPacket === packetName;
  const wrapperNode = document.querySelector(".choose-packet-step__wrapper");

  const isFromPayment = parent === "payment";

  const PacketValues = Object.keys(PACKET_ENUM);

  const isUpgrade =
    PacketValues.indexOf(currentPacket?.toUpperCase()) <
    PacketValues.indexOf(packetName?.toUpperCase());
  const endOfPaymentCycle = moment().add(1, "month");
  return (
    <div className="choose-packet-step__wrapper">
      <div
        className={`choose-packet-step__packet 
        choose-packet-step__packet--${packetName} 
        ${isActive ? "choose-packet-step__packet--active" : ""}
        ${isSelected ? "choose-packet-step__packet--selected" : ""}
        `}
      >
        {isMoreThanPremium && (
          <span className="choose-packet-step__award">
            <SVG type={SVG_ENUM.AWARD} />
          </span>
        )}
        <h2 className="choose-packet-step__packet-name">
          PAKIET {packetName?.toUpperCase()}
        </h2>
        <h4 className="choose-packet-step__price">
          <span>{packetPrice || 0}</span>zł/msc
        </h4>
        {isActive ? (
          <span className="choose-packet-step__actual">AKTUALNY</span>
        ) : (
          <Button
            className="button--rounded button--stroke choose-packet-step__button"
            label="Wybierz"
            onClick={() => {
              setSelectedPacket(packetName);
            }}
          />
        )}
        <hr />
        <ul className="choose-packet-step__ul">
          <li className="choose-packet-step__li">
            <SVG type={SVG_ENUM.CHECK_VERIFIED} />
            Bieżące informacje o zakupionych w Reliance spółkach
          </li>
          {isMoreThanBasic && (
            <li className="choose-packet-step__li">
              <SVG type={SVG_ENUM.CHECK_VERIFIED} />
              Bieżące informacje o wszystkich spółkach które ma Reliance
            </li>
          )}
          {isMoreThanStandard && (
            <li className="choose-packet-step__li">
              <SVG type={SVG_ENUM.CHECK_VERIFIED} />
              Bieżące informacje o spółkach posiadanych w innych firmach
            </li>
          )}
          {isMoreThanPremium && (
            <li className="choose-packet-step__li choose-packet-step__li--bold">
              <SVG type={SVG_ENUM.CHECK_VERIFIED} />
              Jedna spółka do analizy miesięcznie
            </li>
          )}
        </ul>
        <span
          className={`choose-packet-step__selected ${
            isSelected ? "choose-packet-step__selected--active" : ""
          }`}
        >
          Wybrano <SVG type={SVG_ENUM.CIRCLE_CHECK} />
        </span>
      </div>
      {isSelected && wrapperNode && isFromPayment
        ? createPortal(
            isUpgrade ? (
              <p
                className={`choose-packet-step__description choose-packet-step__description--${packetName}`}
              >
                Zmiana pakietu nastąpi od razu, jednak płatność zostanie pobrana
                na początku nowego miesiąca.
              </p>
            ) : (
              <p
                className={`choose-packet-step__description choose-packet-step__description--${packetName}`}
              >
                Zmiana pakietu nastąpi{" "}
                <b>{endOfPaymentCycle.format("DD MMMM YYYY")},</b>jednak usługi
                z obecnego pakietu będą aktywne do końca bieżącego okresu
                rozliczeniowego.
              </p>
            ),
            wrapperNode
          )
        : null}
    </div>
  );
};

export default SinglePacket;
