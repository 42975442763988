import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosConfig from "axiosConfig";
import { ChangePasswordType } from "types/AuthTypes";
import { Toast } from "shared";
import { TOAST_TYPES_ENUM } from "enums";
import { queryClient } from "App";

const changePassword = async (
  credentials: ChangePasswordType
): Promise<any> => {
  const response = await axiosConfig.put(`/users/password`, credentials);
  return response.data;
};

export const useChangePassword = (
  options?: UseMutationOptions<any, Error, ChangePasswordType>
) => {
  return useMutation({
    mutationKey: ["change-password"],
    mutationFn: async (credentials: ChangePasswordType) => {
      return changePassword(credentials);
    },
    onMutate: (variables) => {
      Toast({
        message: "Logowanie...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "change-password",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Hasło zostało zmienione ",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "change-password",
      });
      queryClient.prefetchQuery({ queryKey: ["me"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Nieprawidłowe dane",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "change-password",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
