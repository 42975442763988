import { Button, SVG } from "shared";
import "./Hero.scss";
import { HomepageModalType } from "screens/homePage/Homepage";
import { SVG_ENUM } from "enums";

type HeroPageProps = {
  setModalManage: (type: HomepageModalType | null) => void;
};

const Hero: React.FC<HeroPageProps> = ({ setModalManage }) => {
  return (
    <main className="hero">
      <SVG type={SVG_ENUM.HOMEPAGE_HERO_BACKGROUND} className="hero__bg" />
      <div className="layout">
        <div className="hero__left">
          <h1>
            <i>Nasz sposób</i> na nowoczesne inwestowanie
          </h1>
          <p>
            Wierzymy w moc współczesnej technologii. Dlatego stworzyliśmy
            aplikację, która pomoże naszym klientom podejmować odpowiednie
            decyzje inwestycyjne.
          </p>
          <Button
            styleType="primary"
            size="large"
            label="Sprawdź pakiety"
            onClick={() => setModalManage("packet")}
          />
        </div>
        <div className="hero__right">
          <img
            className="hero__mockup-mobile"
            src={require("assets/images/HomepageHeroMockupMobile.webp")}
            alt="mobile version of dashboard"
          />
          <img
            className="hero__mockup-desktop"
            src={require("assets/images/HomepageHeroMockupMobile2.webp")}
            alt="desktop version of dashboard"
          />
        </div>
      </div>
    </main>
  );
};

export default Hero;
