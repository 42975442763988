import { TOAST_TYPES_ENUM } from "enums";
import { Toast } from "shared";
interface FileItemType {
  name: string;
  url: string;
}

const downloadFile = async ({ name, url }: FileItemType) => {
  
  const handleDownloadFileItem = () => {
    const link = document.createElement("a");
    link.href = url;
    link.download = name;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  try {
    Toast({
      type: TOAST_TYPES_ENUM.LOADING,
      message: "ładowanie",
      id: "downloadFile",
    });
    handleDownloadFileItem();
  } catch (error) {
    Toast({
      type: TOAST_TYPES_ENUM.ERROR,
      message: "Błąd podczas pobierania pliku",
      id: "downloadFile",
    });
  }
};

export default downloadFile;
