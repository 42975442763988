import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { PhonePrefixType } from "types/CountriesTypes";

export default function useGetPhonePrefixes() {
  return useQuery({
    queryKey: ["countries/prefixes"],

    queryFn: async () => {
      return axiosInstance.get(`countries/prefixes`).then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: PhonePrefixType[]) => data,
    staleTime: 1000 * 60 * 60 * 24,
    initialData: [],
    initialDataUpdatedAt: 0,
  });
}
