import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { TOAST_TYPES_ENUM } from "enums";
import { Toast } from "shared";
import { EmailType } from "types/AuthTypes";

export const useResendActivationEmail = (
  options?: UseMutationOptions<any, Error, EmailType>
) => {
  return useMutation({
    mutationKey: ["resendActivationEmail"],
    mutationFn: async (credentials: EmailType) => {
      return axiosInstance
        .post(`/users/resend-link`, credentials)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Ładowanie...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "resendActivationEmail",
      });

      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Wysłano ponownie link aktywacyjny",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "resendActivationEmail",
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },

    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas wysyłania linku aktywacyjnego",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "resendActivationEmail",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
