import { ReactNode } from "react";
import "./phone-with-prefix.scss";

interface PhoneWithPrefixProps {
  children:  ReactNode[] 
}

const PhoneWithPrefix = ({ children }: PhoneWithPrefixProps) => {
  return (
    <div className="phone-with-prefix">
      <div className="phone-with-prefix__content">
        <div className="phone-with-prefix__separator" />
        {children}
      </div>
    </div>
  );
};

export default PhoneWithPrefix;
