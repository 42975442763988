import Table from "rc-table";
import { isDesktop } from "utils";
import { desktopColumns, mobileColumns } from "./utilsArchiveTab";
import { useGetMyCompanies } from "hooks/companies";
import { CompaniesTabsType } from "screens/companiesPage/CompaniesPage";

interface ArchiveTabType {
  tab: CompaniesTabsType["tab"];
  onRowClick?: (record: any, e: React.MouseEvent<any, MouseEvent>) => void;
}

const ArchiveTab = ({ tab, onRowClick }: ArchiveTabType) => {
  const { data: dataOther } = useGetMyCompanies({
    origin: "other",
    enabled: tab === "archive",
    archived: true,
  });
  const { data: dataReliance } = useGetMyCompanies({
    origin: "reliance",
    enabled: tab === "archive",
    archived: true,
  });
  return (
    <>
      {dataReliance?.length ? (
        <>
          <h2 className="companies-table__title">Sprzedane spółki reliance</h2>
          <Table
            rowKey="id"
            data={dataReliance}
            onRow={(record: any) => ({
              onClick: (e) => onRowClick && onRowClick(record, e),
            })}
            columns={isDesktop() ? desktopColumns({}) : mobileColumns()}
          />
        </>
      ) : null}
      {dataOther?.length ? (
        <>
          <h2
            className={`companies-table__title ${
              dataReliance?.length ? "companies-table__title--other" : ""
            }`}
          >
            Inne sprzedane spółki
          </h2>
          <Table
            rowKey="id"
            data={dataOther}
            className="companies-table"
            onRow={(record: any) => ({
              onClick: (e) => onRowClick && onRowClick(record, e),
            })}
            columns={
              isDesktop() ? desktopColumns({ isOther: true }) : mobileColumns()
            }
          />
        </>
      ) : null}
    </>
  );
};

export default ArchiveTab;
