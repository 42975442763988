import { PacketModal } from "features/users";
import { Contact, Hero, HowItWorks, News } from "./components";
import "./Homepage.scss";
import { useState } from "react";

type HomepagePageProps = {};

export type HomepageModalType = "packet";

const Homepage: React.FC<HomepagePageProps> = () => {
  const [modalManage, setModalManage] = useState<HomepageModalType | null>(
    null
  );
  return (
    <div className="homepage">
      <PacketModal
        isOpen={modalManage === "packet"}
        onClose={() => setModalManage(null)}
        parent="homepage"
      />
      <Hero setModalManage={setModalManage} />
      <HowItWorks />
      <News />
      <Contact />
    </div>
  );
};

export default Homepage;
