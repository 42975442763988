import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { ROLES_ENUM } from "enums";
import { UserIndexType } from "types/UsersTypes";
import { MetaType } from "types/UtilsTypes";

export default function useGetUsers(role: ROLES_ENUM, page: number) {
  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      return axiosInstance
        .get(`/users?role=${role}&page=${page}`)
        .then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: { data: UserIndexType[]; meta: MetaType }) => data,
    retry: false,
  });
}
