/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Table from "rc-table";
import { mobileColumns, desktopColumns } from "./utils";
import { Pagination } from "shared";
import { isDesktop } from "utils";
import "./companies-table.scss";
import { useSearchParams } from "react-router-dom";
import { useGetCompanies } from "hooks/companies";
import { useGetIndustriesHook } from "hooks/industries";
import {
  CompanyMyType,
  CompanyStatusType,
  CompanyType,
} from "types/CompanyTypes";
import { Tooltip } from "react-tooltip";
import { ArchiveTab, MyCompaniesTab } from "./components";
import { useGetMe } from "hooks/auth";
import { ROLES_ENUM } from "enums";
import { CompaniesTabsType } from "screens/companiesPage/CompaniesPage";
interface CompaniesTableType {
  page: number;
  setPage: (page: number) => void;
  tab: CompaniesTabsType["tab"];
  onRowClick?: (
    record: CompanyType,
    e: React.MouseEvent<any, MouseEvent>
  ) => void;
  setEditCompany: (company: CompanyType) => void;
  handleStatusChange: (
    company: CompanyType,
    status: CompanyStatusType["status"]
  ) => void;
  setShowSellShares: (company: CompanyMyType) => void;
  setShowDelete: (company: CompanyMyType) => void;
  setModalManage: ({
    type,
    company,
  }: {
    type: string;
    company: CompanyType | CompanyMyType | null;
  }) => void;
}

const CompaniesTable = ({
  setPage,
  page,
  tab,
  onRowClick,
  setEditCompany,
  handleStatusChange,
  setShowSellShares,
  setShowDelete,
  setModalManage,
}: CompaniesTableType) => {
  const { data: industries } = useGetIndustriesHook();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: authorizedUser } = useGetMe();
  const { data, refetch } = useGetCompanies(
    page,
    tab === "reliance" || tab === "other",
    tab,
    authorizedUser?.role === ROLES_ENUM.ADMIN
  );
  useEffect(() => {
    refetch();
  }, [page, tab]);

  const handleChangePage = (e: number) => {
    setPage(e);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", e.toString());
    newSearchParams.set("role", tab);
    setSearchParams(newSearchParams);
  };
  const isAdmin = authorizedUser?.role === ROLES_ENUM.ADMIN;

  return (
    <div className="">
      <Tooltip
        place="top"
        className="tooltip tooltip--noShadow"
        id="company-tooltip"
      />
      {tab === "my" ? (
        <MyCompaniesTab
          industries={industries}
          tab={tab}
          setEditCompany={setEditCompany}
          handleStatusChange={handleStatusChange}
          onRowClick={onRowClick}
          setShowSellShares={setShowSellShares}
          setShowDelete={setShowDelete}
        />
      ) : null}

      {tab === "reliance" ? (
        <>
          <Table
            rowKey="id"
            className="companies-table companies-table--reliance"
            data={data?.data}
            onRow={(record: any) => ({
              onClick: (e) => onRowClick && isAdmin && onRowClick(record, e),
            })}
            columns={
              isDesktop()
                ? desktopColumns({
                    setEditCompany,
                    handleStatusChange,
                    isAdmin,
                    setModalManage,
                  })
                : mobileColumns()
            }
          />
          {data?.meta && data?.meta?.lastPage > 1 && (
            <Pagination
              totalPages={data?.meta?.lastPage}
              currentPage={data?.meta?.currentPage || page}
              onPageChange={(e: any) => handleChangePage(e)}
            />
          )}
        </>
      ) : null}
      {tab === "other" ? (
        <>
          <Table
            rowKey="id"
            className="companies-table"
            data={data?.data}
            onRow={(record: any) => ({
              onClick: (e) => onRowClick && onRowClick(record, e),
            })}
            columns={
              isDesktop()
                ? desktopColumns({
                    setEditCompany,
                    handleStatusChange,
                    isAdmin: authorizedUser?.role === ROLES_ENUM.ADMIN,
                    setModalManage,
                  })
                : mobileColumns()
            }
          />
          {data?.meta && data?.meta?.lastPage > 1 && (
            <Pagination
              totalPages={data?.meta?.lastPage}
              currentPage={data?.meta?.currentPage || page}
              onPageChange={(e: any) => handleChangePage(e)}
            />
          )}
        </>
      ) : null}

      {tab === "archive" ? (
        <ArchiveTab tab={tab} onRowClick={onRowClick} />
      ) : null}
    </div>
  );
};

export default CompaniesTable;
