import RoutesComponent from "Routes";
import "assets/styles/main.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "react-tooltip/dist/react-tooltip.css";
import moment from "moment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "moment/locale/pl";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

export const queryClient = new QueryClient();
function App() {
  moment.locale("pl");

  return (
    <div className="App">
      <Elements stripe={stripePromise}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <RoutesComponent />
          </BrowserRouter>
          <ToastContainer
            stacked
            position="bottom-right"
            autoClose={3000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Elements>
    </div>
  );
}

export default App;
