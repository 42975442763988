import { VALIDATION_ENUM } from "enums";
import * as Yup from "yup";

const validation = () =>
  Yup.object().shape({
    deadline: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    status: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    file: Yup.mixed()
      .nullable()
      .test("fileRequired", VALIDATION_ENUM.REQUIRED, function (value) {
        if (this.parent.status === "completed" && !value) {
          return false;
        }
        return true;
      }),
    explanation: Yup.string().test(
      "explanationRequired",
      VALIDATION_ENUM.REQUIRED,
      function (value) {
        if (this.parent.status === "canceled" && !value) {
          return false;
        }
        return true;
      }
    ),
  });

export default validation;
