import { SVG } from "shared";
import { SVG_ENUM } from "enums";

import { downloadFile, getCurrencyFromUuid } from "utils";
import { TransactionType } from "types/TransactionsTypes";
import moment from "moment";
import { CompanyMyType } from "types/CompanyTypes";

export const mobileColumns = (company: CompanyMyType) => [
  {
    width: "calc(100% - 20px)",
    render: (data: TransactionType) => (
      <table className="side-modal__table">
        <tr>
          <td className="side-modal__table-label">Transakcja:</td>
          <td className="side-modal__table-cell">
            {data.type === "buy" ? "Zakup" : "Sprzedaż"}
          </td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Data:</td>
          <td className="side-modal__table-cell">
            {moment(data.createdAt).format("DD/MM/YYYY")}
          </td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Ilość udziałów:</td>
          <td className="side-modal__table-cell">
            {
              <span
                className={`transactions-table__number transactions-table__number--${data?.type}`}
              >
                {data?.type === "buy" ? "+" : "-"}
                {data?.sharesCount}
              </span>
            }
          </td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Wartość udziałów:</td>
          <td className="side-modal__table-cell">
            {
              <span
                className={`transactions-table__number transactions-table__number--${data?.type}`}
              >
                {data?.type === "buy" ? "+" : "-"}
                {data?.sharesValue}
              </span>
            }
          </td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Waluta:</td>
          <td className="side-modal__table-cell">
            {getCurrencyFromUuid(company?.currencyUuid)}
          </td>
        </tr>
      </table>
    ),
  },

  {
    width: 88,
    render: () => (
      <SVG className="transactions-table__invoice" type={SVG_ENUM.FILE} />
    ),
  },
];

export const desktopColumns = (company: CompanyMyType) => [
  {
    title: "Transakcja",
    width: 150,
    render: (item: TransactionType) => (
      <span className="clients-table__name">
        {item?.type === "buy" ? "Zakup" : "Sprzedaż"}
      </span>
    ),
  },
  {
    title: "Data",
    width: 150,
    dataIndex: "completedAt",
    key: "completedAt",
    render: (item: string) => moment(item).format("DD/MM/YYYY"),
  },
  {
    title: "Ilość udziałów",
    width: 200,
    render: (item: TransactionType) => (
      <span
        className={`transactions-table__number transactions-table__number--${item?.type}`}
      >
        {item?.type === "buy" ? "+" : "-"}
        {item?.sharesCount}
      </span>
    ),
  },
  {
    title: "Wartość udziałów",
    width: 200,
    render: (item: TransactionType) =>
      item?.sharesValue ? (
        <span
          className={`transactions-table__number transactions-table__number--${item?.type}`}
        >
          {item?.type === "buy" ? "+" : "-"}
          {item?.sharesValue}
        </span>
      ) : (
        "-"
      ),
  },
  {
    title: "Waluta",
    width: 40,
    render: (item: TransactionType) => {
      return getCurrencyFromUuid(company?.currencyUuid);
    },
  },
  {
    width: 40,
    render: (item: TransactionType) => {
      return item?.file ? (
        <span
          className="clients-table__download"
          onClick={() =>
            downloadFile({ name: item.file.name, url: item.file.url })
          }
        >
          <SVG type={SVG_ENUM.FILE} />
        </span>
      ) : null;
    },
  },
];

export const summaryColumns = (item: CompanyMyType) => [
  {
    key: "type",
    width: 300,
    render: () => {
      return "Stan obecny";
    },
  },
  {
    width: 200,
    render: (item: CompanyMyType) => item?.sharesCount,
  },
  {
    width: 200,
    render: (item: CompanyMyType) =>
      item?.sharesValue ? item?.sharesValue : "-",
  },
  {
    width: 80,
  },
];
