import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { AddPaymentMethodType } from "types/PaymentTypes";

export const useStorePaymentMethod = (
    options?: UseMutationOptions<any, Error, AddPaymentMethodType>
) => {
    return useMutation({
        ...options,
        mutationKey: ["addPaymentMethod"],
        mutationFn: async ({ stripePmToken }) => {
            console.log('making something')
            return axiosInstance
                .post("/payment-methods", {
                    stripe_pm_token: stripePmToken,
                })
                .then((res) => res.data);
        },
    });
};

export default useStorePaymentMethod;
