import { useDeleteCompanyClient } from "hooks/companies";
import { Button, PopupModal } from "shared";
import { CompanyMyType } from "types/CompanyTypes";

interface DeleteCompanyModalProps {
  isOpen: boolean;
  onClose: () => void;
  company: CompanyMyType | null;
  onSuccess?: () => void;
}

const DeleteCompanyModal: React.FC<DeleteCompanyModalProps> = ({
  isOpen,
  onClose,
  company,
  onSuccess,
}) => {
  const { mutate: deleteCompanyClient } = useDeleteCompanyClient();

  const handleDeleteFilter = async () => {
    if (company) {
      await deleteCompanyClient(
        { shareUuid: company?.sharesUuid },
        {
          onSuccess: () => {
            onSuccess && onSuccess();
            onClose();
          },
        }
      );
      onClose();
    }
  };
  return (
    <PopupModal isOpen={isOpen} onClose={onClose}>
      <h2 className="popup-modal__header">Usuwanie spółki</h2>
      <p className="popup-modal__subtitle">
        Czy jesteś pewien, że chcesz usunąć spółkę
        <span className="fw-600"> {company?.name}</span>?
      </p>
      <div className="side-modal__buttons">
        <Button label="Anuluj" onClick={onClose} className="button--stroke" />
        <Button
          label="Potwierdź"
          onClick={() => handleDeleteFilter()}
          className=" button--navy"
        />
      </div>
    </PopupModal>
  );
};

export default DeleteCompanyModal;
