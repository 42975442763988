import { SVG_ENUM } from "enums";
import { EditPersonalDataModal } from "features/users";
import { useGetPhonePrefixes } from "hooks/phone";
import React, { useState } from "react";
import { Button, SVG } from "shared";
import { UserType } from "types/UsersTypes";
import { getPrefixFromUuid } from "utils";

interface PersonalDataProps {
  user: UserType | undefined;
  handleGoBack: () => void;
}

const PersonalData: React.FC<PersonalDataProps> = ({ user, handleGoBack }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: prefixNumbers } = useGetPhonePrefixes();

  return (
    <div className="profile__right-wrapper">
      <EditPersonalDataModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        user={user || null}
      />
      <div className="profile__right-back" onClick={() => handleGoBack()}>
        <SVG type={SVG_ENUM.ARROW_LEFT} /> Wróć
      </div>
      <h2 className="profile__menu-header">
        <span className="profile__menu-icon profile__menu-icon--right">
          <SVG type={SVG_ENUM.USER} />
        </span>
        Dane osobowe
      </h2>
      <table className="profile__table">
        <tr>
          <td className="profile__table-label">Imię i nazwisko:</td>
          <td className="profile__table-cell">{user?.name || "-"}</td>
        </tr>
        <tr>
          <td className="profile__table-label">Telefon:</td>
          <td className="profile__table-cell">
            {user?.phonePrefixUuid
              ? getPrefixFromUuid(user?.phonePrefixUuid, prefixNumbers)
              : ""}{" "}
            {user?.phoneNumber || "-"}
          </td>
        </tr>
        <tr>
          <td className="profile__table-label">Email:</td>
          <td className="profile__table-cell">{user?.email || "-"}</td>
        </tr>
      </table>
      <Button
        onClick={() => setIsModalOpen(true)}
        className="profile__button button--stroke button--rounded"
        label="Edytuj"
      />
    </div>
  );
};

export default PersonalData;
