import "./user-status.scss";

interface UserStatusType {
  status: string;
}

const UserStatus = ({ status }: UserStatusType) => {
  return (
    <div className={`user-status`}>
      <span
        className={`user-status__label 
          ${status === "active" ? "user-status__label--active" : ""}
          ${status === "suspended" ? "user-status__label--blocked" : ""}
          ${status === "inactive" ? "user-status__label--inactive" : ""}`}
      >
        {status === "active" && "Aktywny"}
        {status === "suspended" && "Zawieszony"}
        {status === "inactive" && "Nieaktywny"}
      </span>
    </div>
  );
};

export default UserStatus;
