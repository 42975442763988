import { SVG_ENUM } from "enums";
import React, { useEffect, useState } from "react";
import "./Pagination.scss";
import SVG from "shared/SVG/SVG";

interface Props {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  className?: string;
}

const Pagination: React.FC<Props> = ({
  totalPages,
  currentPage,
  onPageChange,
  className = "",
}) => {
  const [localPage, setLocalPage] = useState(currentPage);
  const [pagesToShow, setPagesToShow] = useState([1]);
  const handlePreviousClick = () => {
    if (localPage > 1) {
      setLocalPage(localPage - 1);
      onPageChange(localPage - 1);
    }
  };

  const handleNextClick = () => {
    if (localPage < totalPages) {
      setLocalPage(localPage + 1);
      onPageChange(localPage + 1);
    }
  };
  const handlePageClick = (page: number) => {
    setLocalPage(page);
    onPageChange(page);
  };

  useEffect(() => {
    if (totalPages === 2) {
      setPagesToShow([]);
    } else if (totalPages === 3) {
      setPagesToShow([2]);
    } else if (totalPages === 4) {
      setPagesToShow([2, 3]);
    } else if (localPage === 1) {
      setPagesToShow([2, 3, 4]);
    } else if (localPage === 2) {
      setPagesToShow([2, 3, 4]);
    } else if (localPage === totalPages - 1 || localPage === totalPages) {
      setPagesToShow([totalPages - 3, totalPages - 2, totalPages - 1]);
    } else {
      setPagesToShow([localPage - 1, localPage, localPage + 1]);
    }
  }, [localPage, totalPages]);

  useEffect(() => {
    if (currentPage === 1) {
      setLocalPage(currentPage);
    }
  }, [currentPage]);

  return (
    <div className={`pagination ${className}`}>
      <button
        onClick={handlePreviousClick}
        className={`pagination__prev ${
          localPage === 1 ? "pagination--disabled" : ""
        }`}
      >
        <SVG type={SVG_ENUM.BACK_CHEVRON} />
      </button>
      <div className="pagination__pages">
        <>
          <button
            key={1}
            onClick={() => handlePageClick(1)}
            className={`pagination__page ${
              localPage === 1 ? "pagination__page--current" : ""
            }`}
          >
            {1}
          </button>
          {totalPages > 5 && pagesToShow[0] !== 2 ? <span>...</span> : null}
        </>
        {pagesToShow.map((page) => (
          <button
            key={page}
            onClick={() => handlePageClick(page)}
            className={`pagination__page ${
              localPage === page ? "pagination__page--current" : ""
            }`}
          >
            {page}
          </button>
        ))}

        {totalPages - localPage > 2 &&
        pagesToShow[pagesToShow.length - 1] !== totalPages - 1 ? (
          <span>...</span>
        ) : null}
        {totalPages !== 0 ? (
          <button
            key={totalPages}
            onClick={() => handlePageClick(totalPages)}
            className={`pagination__page ${
              localPage === totalPages ? "pagination__page--current" : ""
            }`}
          >
            {totalPages}
          </button>
        ) : null}
      </div>
      <button
        onClick={handleNextClick}
        className={`pagination__next ${
          localPage === totalPages || totalPages === 0
            ? "pagination--disabled"
            : ""
        }`}
      >
        <SVG type={SVG_ENUM.BACK_CHEVRON} />
      </button>
    </div>
  );
};

export default Pagination;
