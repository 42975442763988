import { SVG_ENUM } from "enums";
import React, { useState } from "react";
import { Button, SVG } from "shared";
import { UserType } from "types/UsersTypes";
import "./Payments.scss";
import { PacketModal } from "features/users";
import { useGetMe } from "hooks/auth";
import { useGetPaymentMethods, useGetPayments } from "hooks/payments";
import { PACKET_PRICE_ENUM } from "enums/PacketsEnum";

interface PaymentsProps {
  user: UserType | undefined;
  handleGoBack: () => void;
}

const Payments: React.FC<PaymentsProps> = ({ user, handleGoBack }) => {
  const [isPacketModalOpen, setIsPacketModalOpen] = useState(false);
  const { data: me } = useGetMe();
  const { data: paymentMethods } = useGetPaymentMethods();
  const { data: payments } = useGetPayments();
  return (
      <div className="profile__right-wrapper payments">
          <PacketModal
              isOpen={isPacketModalOpen}
              onClose={() => setIsPacketModalOpen(false)}
              parent="payment"
          />
          <div className="profile__right-back" onClick={() => handleGoBack()}>
              <SVG type={SVG_ENUM.ARROW_LEFT}/> Wróć
          </div>
          <h2 className="profile__menu-header">
        <span className="profile__menu-icon profile__menu-icon--right">
          <SVG type={SVG_ENUM.CREDIT_CARD}/>
        </span>
              Płatności
          </h2>
          <p className="payments__label">Twój pakiet</p>
          <div className="payments__package">
              <SVG type={SVG_ENUM.LOGIN_BG_MOBILE} className="payments__package-bg"/>
              <div className="payments__package-left">
                  <p className="payments__package-name">Pakiet {me?.package}</p>
                  <p>
            <span className="payments__package-price">
              {
                  PACKET_PRICE_ENUM[
                      me?.package?.toLocaleUpperCase() as keyof typeof PACKET_PRICE_ENUM
                      ]
              }
            </span>
                      zł/msc
                  </p>
              </div>
              <Button
                  label="Zmień"
                  className="button-rounded payments__package-button"
                  onClick={() => setIsPacketModalOpen(true)}
              />
          </div>
          <div className="payments__payment-methods">
              <div className="payments__payment-methods--header">
                  <p className="payments__label">
                      Metody płatności
                  </p>
                  <Button
                      onClick={() => console.log('dupa-test')}
                      className="button--stroke button--rounded button--payments"
                      label="Dodaj kartę płatniczą +"
                  />
              </div>
              <div className="payments__payment-methods--items-wrapper">
                  <p className="payments__payment-methods--label">Zapisane karty</p>
                  {paymentMethods?.map((paymentMethod) => (
                      <>
                      {console.log(paymentMethod)}
                      <div className="payments__payment-methods--item">
                          <SVG type={SVG_ENUM.CARD} className="payments__payment-methods--card"/>
                          <p className="payments__payment-methods--number">
                              ****2950
                          </p>
                          <div className="payments__payment-methods--options">
                              Wybrano
                          </div>
                          <SVG type={SVG_ENUM.TRASH} className="payments__payment-methods--trash"/>
                      </div>
                      </>
                  ))}
              </div>
          </div>
          <div className="payments__invoices">
              <div className="payments__invoices--header">
                  <p className="payments__label">
                      Faktury
                  </p>
              </div>
              <div className="payments__invoices--items-wrapper">
                  <p className="payments__invoices--label">
                      Następna płatność:
                      <p className="payments__invoices--label--bold">
                          01/11/2024r.
                      </p>
                  </p>
                  {payments?.map((payment) => (
                      <>
                          {console.log(payments)}
                          <div className="payments__invoices--item">
                              <p className="payments__invoices--date">
                                  &ensp;2024-03-1039243
                              </p>
                              <p className="payments__invoices--amount">
                                  15 zł
                              </p>
                              <SVG type={SVG_ENUM.CLOUD_DOWNLOAD} className="payments__invoices--cloud-icon"/>
                              <p className="payments__invoices--download">
                                  Pobierz
                              </p>
                          </div>
                      </>
                  ))}
              </div>
          </div>
      </div>
  );
};

export default Payments;
