import { CompanyStatusType } from "types/CompanyTypes";
import "./company-status.scss";

const CompanyStatus = ({ status }: CompanyStatusType) => {
  return (
    <div className={`company-status`}>
      <span
        className={`company-status__label 
          ${status === "active" ? "company-status__label--active" : ""}
          ${status === "withdrawn" ? "company-status__label--withdrawn" : ""}
          ${status === "hidden" ? "company-status__label--hidden" : ""}`}
      >
        {status === "active" && "Aktywny"}
        {status === "withdrawn" && "Wycofana"}
        {status === "hidden" && "Ukryta"}
      </span>
    </div>
  );
};

export default CompanyStatus;
