import { VALIDATION_ENUM } from "enums";
import React, { useEffect, useState } from "react";
import "./EnterSmsCodeStep.scss";
import { EnterCode } from "shared";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useLoginSms } from "hooks/auth";

interface Props {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  setStepOptions: any;
  stepOptions: any;
}

const EnterSmsCodeStep: React.FC<Props> = ({
  goToNextStep,
  goToPreviousStep,
  setStepOptions,
  stepOptions,
}) => {
  const [code, setCode] = useState<string[]>(["", "", "", ""]);


  const { mutate } = useLoginSms({
    onSuccess: () => {
      goToNextStep();
    },
    onError: (error: Error) => {
      console.error("Błąd podczas logowania:", error.message);
    },
  });

  const validation = () =>
    Yup.object().shape({
      smsCode: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });

  const initialValues = {
    smsCode: "",
  };

  const onSubmit = (values: typeof initialValues) => {
    const smsCode = code.join("");
    mutate({ smsCode });
  };

  useEffect(() => {
    if (code.join("").length === 4) {
      onSubmit({ smsCode: code.join("") });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <>
      <h2 className="fullscreen-modal__header packet-modal__header">
        Uzupełnij swoje dane
      </h2>
      <div className="enter-sms-code-step">
        <p className="login-wrapper__subtitle">
          Wprowadź 4-cyfrowy kod, który przesłaliśmy Ci w wiadomości SMS.
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values)}
          validationSchema={validation()}
        >
          {({ errors, values, handleSubmit }) => (
            <Form>
              <EnterCode setCode={setCode} code={code} />

              <p
                className="login-wrapper__anchor enter-code-page__anchor"
                onClick={() => goToPreviousStep()}
              >
                Nie otrzymałeś kodu? <span> Wyślij ponownie</span>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EnterSmsCodeStep;
