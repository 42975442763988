import React, { useRef, useState } from "react";
import { Button, Input, LoginWrapper, SVG } from "shared";
import "./ResetPasswordPage.scss";
import { Field, Formik, Form } from "formik";
import { ROUTE_ENUM, SVG_ENUM, VALIDATION_ENUM } from "enums";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useResetPassword } from "hooks/auth";

type ResetPasswordPageProps = {};

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = () => {
  const navigate = useNavigate();
  const formRef = useRef<any>(null);
  const [isDone, setIsDone] = useState(false);

  const { mutate } = useResetPassword({
    onSuccess: () => {
      setIsDone(true);
    },
    onError: (error: Error) => {
      console.error("Błąd podczas logowania:", error.message);
    },
  });

  const validation = () =>
    Yup.object().shape({
      email: Yup.string()
        .email(VALIDATION_ENUM.WRONG_EMAIL)
        .required(VALIDATION_ENUM.REQUIRED),
    });

  const initialValues = {
    email: "",
  };

  const onSubmit = (values: typeof initialValues) => {
    mutate(values);
  };

  return (
    <LoginWrapper className="reset-password">
      {isDone ? (
        <>
          <h1 className="login-wrapper__header">
            Wysłano link do resetu hasła
          </h1>
          <p className="login-wrapper__subtitle">
            Jeśli podany przez Ciebie adres Email jest zgodny z adresem
            wprowadzonym przy zakładaniu konta, to powinieneś otrzymać wiadomość
            na swoją skrzynkę mailową.
            <br />
            <br />
            W celu zresetowania hasła, kliknij w przesłany link.
          </p>
          <p className="reset-password__link">Link nie dotarł?</p>
          <Button
            label="Wyślij link ponownie"
            className="button--black button--rounded login-wrapper__submit login-wrapper__submit--no-margin button--wide"
            onClick={() => {
              setIsDone(false);
            }}
          />
        </>
      ) : (
        <>
          <div className="login-wrapper__back-wrapper">
            <span
              className="login-wrapper__back"
              onClick={() => navigate(ROUTE_ENUM.LOGIN_ADMIN)}
            >
              <SVG type={SVG_ENUM.ARROW_LEFT} />
            </span>
            <h1 className="login-wrapper__header">Nie pamiętasz hasła?</h1>
          </div>
          <p className="login-wrapper__subtitle">
            W prowadź swój adres Email, ay otrzymać instrukcje dotyczące
            resetowania hasła.
          </p>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={validation()}
            innerRef={formRef}
          >
            {({ errors, values, handleSubmit, setFieldValue }) => (
              <Form>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  label={"E-mail"}
                  as={Input}
                />{" "}
                <Button
                  label="Wyślij"
                  className="button--black button--rounded login-wrapper__submit login-wrapper__submit--no-margin"
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </Form>
            )}
          </Formik>
        </>
      )}
    </LoginWrapper>
  );
};

export default ResetPasswordPage;
