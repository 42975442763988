import { useState, useRef, useEffect } from "react";
import "./DesktopMenu.scss";
import { NavLink } from "react-router-dom";
import { ROUTE_ENUM, SVG_ENUM, ROLES_ENUM } from "enums";
import { SVG } from "shared";
import { UserType } from "types/UsersTypes";
import { useLogout } from "hooks/auth";

interface DesktopMenuType {
  user: UserType | undefined;
}

const DesktopMenu = ({ user }: DesktopMenuType) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navigationRef = useRef<HTMLElement | null>(null);
  const { mutate: logout } = useLogout({});
  const isAdmin = user?.role === ROLES_ENUM.ADMIN;

  useEffect(() => {
    const navigationNode = navigationRef.current;

    if (navigationNode) {
      const handleMouseEnter = () => setIsNavOpen(true);
      const handleMouseLeave = () => setIsNavOpen(false);

      navigationNode.addEventListener("mouseenter", handleMouseEnter);
      navigationNode.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        navigationNode.removeEventListener("mouseenter", handleMouseEnter);
        navigationNode.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [navigationRef]);

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <nav
        ref={navigationRef}
        className={`desktop-menu ${isNavOpen ? "desktop-menu--open" : ""}`}
      >
        <div
          className="desktop-menu-item desktop-menu__logo"
          onClick={() => setIsNavOpen(!isNavOpen)}
        >
          <SVG type={SVG_ENUM.RELIANCE} />
        </div>
        {isAdmin ? (
          <>
            <NavLink
              to={`${ROUTE_ENUM.COMPANIES}`}
              className="desktop-menu-item"
            >
              <SVG type={SVG_ENUM.LIST_UNORDERED} />
              <div className="desktop-menu-item__name">Spółki</div>
            </NavLink>
            <NavLink
              to={`${ROUTE_ENUM.MARKET_INFORMATION}`}
              className="desktop-menu-item"
            >
              <SVG type={SVG_ENUM.ANNOUCEMENT} />
              <div className="desktop-menu-item__name">Informacje</div>
            </NavLink>
            <NavLink
              to={`${ROUTE_ENUM.ANALYSIS}`}
              className="desktop-menu-item"
            >
              <SVG type={SVG_ENUM.PRESENTATION} />
              <div className="desktop-menu-item__name">Analiza</div>
            </NavLink>
            <NavLink to={`${ROUTE_ENUM.USERS}`} className="desktop-menu-item">
              <SVG type={SVG_ENUM.USER_GROUP} />
              <div className="desktop-menu-item__name">Użytkownicy</div>
            </NavLink>
            <NavLink
              to={`${ROUTE_ENUM.PAYMENTS}`}
              className="desktop-menu-item"
            >
              <SVG type={SVG_ENUM.CREDIT_CARD} />
              <div className="desktop-menu-item__name">Płatności</div>
            </NavLink>
          </>
        ) : (
          <>
            <NavLink
              to={`${ROUTE_ENUM.COMPANIES}`}
              className="desktop-menu-item"
            >
              <SVG type={SVG_ENUM.LIST_UNORDERED} />
              <div className="desktop-menu-item__name">Spółki</div>
            </NavLink>
            <NavLink
              to={`${ROUTE_ENUM.MARKET_INFORMATION}`}
              className="desktop-menu-item"
            >
              <SVG type={SVG_ENUM.ANNOUCEMENT} />
              <div className="desktop-menu-item__name">Informacje</div>
            </NavLink>
            <NavLink
              to={`${ROUTE_ENUM.ANALYSIS}`}
              className="desktop-menu-item"
            >
              <SVG type={SVG_ENUM.PRESENTATION} />
              <div className="desktop-menu-item__name">Analiza</div>
            </NavLink>
            <NavLink to={`${ROUTE_ENUM.PROFILE}`} className="desktop-menu-item">
              <SVG type={SVG_ENUM.USER} />
              <div className="desktop-menu-item__name">Profil</div>
            </NavLink>
          </>
        )}

        <div
          onClick={() => handleLogout()}
          className={`desktop-menu-item desktop-menu-item--employee-logout`}
        >
          <SVG type={SVG_ENUM.LOGOUT} />
          <div className="desktop-menu-item__name">Wyloguj się</div>
        </div>
      </nav>
    </>
  );
};

export default DesktopMenu;
