import { queryClient } from "App";
import axiosInstance from "axiosConfig";
import { TOAST_TYPES_ENUM } from "enums";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { Toast } from "shared";
import { LoginTypes } from "types/AuthTypes";
import { toast } from "react-toastify";

export const useLogin = (
  options?: UseMutationOptions<any, Error, LoginTypes>
) => {
  return useMutation({
    mutationKey: ["login"],
    mutationFn: async (credentials: LoginTypes) => {
      return axiosInstance
        .post(`/auth/login`, credentials)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Logowanie...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "login",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Zalogowano pomyślnie",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "login",
      });
      queryClient.prefetchQuery({ queryKey: ["me"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error: any, variables, context) => {
      if (error?.status === 500) {
        Toast({
          message: "Błąd serwera",
          type: TOAST_TYPES_ENUM.ERROR,
          id: "login",
        });
      } else {
        toast.dismiss("login");
      }
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
