import { Button, Modal } from "shared";
import { useSuspend } from "hooks/users";
// import { UserType } from "types/UsersTypes";

interface BlockUserType {
  user: any;
  isOpen: boolean;
  onClose: () => void;
}

const BlockUser = ({ isOpen, onClose, user }: BlockUserType) => {
  const { mutate: suspendUser } = useSuspend();
  const isUserBlocked = !user?.suspended;

  const handleSuspendUser = async (uuid: string) => {
    await suspendUser(uuid);

    onClose();
  };

  return (
    <>
      <Modal isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">
            {isUserBlocked
              ? "Zawieszanie użytkownika"
              : "Przywracanie użytkownika"}
          </div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz{" "}
            {isUserBlocked ? "zawiesić" : "przywrócić"} użytkownika
            <span>{`${user?.name} `}</span>?
          </div>
          <div className="modal-buttons">
            <Button
              className="button--stroke button--rounded"
              onClick={() => onClose()}
              label="Anuluj"
            />
            <Button
              className="button--black button--rounded"
              onClick={() => handleSuspendUser(user.uuid)}
              label="Potwierdź"
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default BlockUser;
