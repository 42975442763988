import { Outlet } from "react-router-dom";
import "./DashboardPage.scss";
import { DesktopMenu, MobileMenu } from "shared";
import { useGetMe } from "hooks/auth";

const DashboardPage = () => {
  const { data: authorizedUser } = useGetMe();

  return (
    <div className="dashboard">
      <DesktopMenu user={authorizedUser} />
    
        <Outlet />
     
      <MobileMenu user={authorizedUser} />
    </div>
  );
};

export default DashboardPage;
