import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { TransactionType } from "types/TransactionsTypes";

export default function useGetTransaction(uuid: string) {
  return useQuery({
    queryKey: ["transactions", uuid],
    queryFn: async () => {
      return axiosInstance.get(`/transactions/${uuid}`).then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: TransactionType[]) => data,
    retry: false,
    enabled: !!uuid,
    staleTime: 1000 * 60 * 5,
    initialDataUpdatedAt: Date.now(),
  });
}
