import { toast } from "react-toastify";
import {  TOAST_TYPES_ENUM } from "enums";
import "./Toast.scss";

interface ToastProps {
  message: string;
  type: TOAST_TYPES_ENUM;
  id: string;
}

const Toast = ({ message, type, id }: ToastProps) => {
  const handleShowToast = () => {
    switch (type) {
      case TOAST_TYPES_ENUM.SUCCESS:
        toast.update(id, {
          render: (
            <div>
              <h2 className="toast-title toast-title--success">Sukcess</h2>
              <p>{message}</p>
            </div>
          ),
          type: "success",
          isLoading: false,
          closeButton: true,
          autoClose: 3000,
        });
        break;
      case TOAST_TYPES_ENUM.ERROR:
        toast.update(id, {
          render: (
            <div>
              <h2 className="toast-title toast-title--failure">Błąd</h2>
              <p>{message}</p>
            </div>
          ),
          type: "error",
          isLoading: false,
          closeButton: true,
          autoClose: 3000,
        });
        break;
      case TOAST_TYPES_ENUM.LOADING:
        toast.loading("Ładowanie", { toastId: id });
        break;
      default: {
        console.error(`Niepoprawny typ notyfikacji (${type})`);
      }
    }
  };

  return <>{handleShowToast()}</>;
};

export default Toast;
