/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Button, DashboardTopBar, DashboardTopPortal } from "shared";
import {
  BlockUser,
  //   DeleteUser,
  //   BlockUser,
  AddAdmin,
  //   EditUser,
  UsersTable,
  UserInfoModal,
  ChangePasswordModal,
  EditUserByAdmin,
  EditAddressDataModal,
} from "features/users";
import { useResendActivationEmail } from "hooks/users";
import { useSearchParams } from "react-router-dom";
import { UserType } from "types/UsersTypes";
import { ROLES_ENUM } from "enums";
import "./users.scss";
import { useGetMe } from "hooks/auth";

const UsersPage = () => {
  const { mutate: resendActivationLink } = useResendActivationEmail();
  const { data: me } = useGetMe();
  const [searchParams, setSearchParams] = useSearchParams();
  const linkPage = searchParams.get("page");
  interface ModalManage {
    type: string;
    user: UserType | null;
  }

  const [modalManage, setModalManage] = useState<ModalManage>({
    type: "",
    user: null,
  });
  const [page, setPage] = useState(Number(linkPage) || 1);

  const [tab, setTab] = useState(ROLES_ENUM.USER);

  const isAdminTab = tab === ROLES_ENUM.ADMIN;
  // const isDeleteUserOpenModal = modalManage.type === "delete";
  const isBlockUserOpenModal = modalManage.type === "block";
  const isUserDetailsOpenModal = modalManage.type === "details";
  const isAddUserOpenModal = modalManage.type === "addAdmin";
  const isChangePasswordOpenModal = modalManage.type === "changePassword";
  const clearModalManage = () => {
    setModalManage({ type: "", user: modalManage.user });
  };

  const setDetailsUser = (
    user: UserType,
    e: React.MouseEvent<any, MouseEvent>
  ) => {
    if (
      e.target instanceof Element &&
      e.target.closest(".context-menu__wrapper")
    ) {
      return;
    }
    setModalManage({ type: "details", user });
  };

  const setDeleteUser = (user: UserType) =>
    setModalManage({ type: "delete", user });

  const setBlockUser = (user: UserType) =>
    setModalManage({ type: "block", user });

  const setAddUser = () => setModalManage({ type: "addAdmin", user: null });

  const setEditAdmin = (user: UserType) =>
    setModalManage({ type: "addAdmin", user });

  const setEditUser = (user: UserType) =>
    setModalManage({ type: "editUser", user });

  const setChangePassword = () =>
    setModalManage({ type: "changePassword", user: null });

  const setEditPersonalData = (user: UserType) => {
    setModalManage({ type: "editPersonalData", user });
  };

  const resendActivateMail = (email: string) => {
    resendActivationLink({ email });
  };

  const handleChangeTab = (item: ROLES_ENUM) => {
    setTab(item);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", "1");
    newSearchParams.set("role", item);
    setSearchParams(newSearchParams);
  };

  return (
    <>
      {/* <DeleteUser
        user={modalManage.user}
        isOpen={isDeleteUserOpenModal}
        onClose={() => clearModalManage()}
      /> */}
      <UserInfoModal
        isOpen={isUserDetailsOpenModal}
        onClose={() => clearModalManage()}
        user={modalManage.user}
        setEditPersonalData={setEditPersonalData}
      />
      <BlockUser
        user={modalManage.user}
        isOpen={isBlockUserOpenModal}
        onClose={() => clearModalManage()}
      />
      <AddAdmin
        isOpen={isAddUserOpenModal}
        onClose={() => clearModalManage()}
        user={modalManage?.user ? modalManage.user : null}
      />
      <EditUserByAdmin
        isOpen={modalManage.type === "editUser"}
        onClose={() => clearModalManage()}
        user={modalManage.user}
      />
      <ChangePasswordModal
        isOpen={isChangePasswordOpenModal}
        onClose={() => clearModalManage()}
      />

      <EditAddressDataModal
        isOpen={modalManage.type === "editPersonalData"}
        onClose={() => clearModalManage()}
        user={modalManage.user}
      />

      <div className="users">
        <DashboardTopBar>
          <h1 className="dashboard__header">Użytkownicy</h1>
          {isAdminTab && (
            <Button
              className="dashboard__top-button button--rounded"
              onClick={() => setAddUser()}
              label="Dodaj administratora +"
            />
          )}
        </DashboardTopBar>
        <div className="dashboard-content__wrapper">
          <div className="dashboard-content">
            <DashboardTopPortal>
              <div className="dashboard__tabs">
                <span
                  onClick={() => handleChangeTab(ROLES_ENUM.USER)}
                  className={`dashboard__tab ${
                    tab === ROLES_ENUM.USER ? "dashboard__tab--active" : ""
                  } `}
                >
                  Klienci
                </span>
                <span
                  onClick={() => handleChangeTab(ROLES_ENUM.ADMIN)}
                  className={`dashboard__tab ${
                    tab === ROLES_ENUM.ADMIN ? "dashboard__tab--active" : ""
                  } `}
                >
                  Administratorzy
                </span>
              </div>
            </DashboardTopPortal>
            <UsersTable
              page={page}
              setPage={setPage}
              isAdminTab={isAdminTab}
              editUser={setEditUser}
              editAdmin={setEditAdmin}
              blockUser={setBlockUser}
              deleteUser={setDeleteUser}
              resendActivateMail={resendActivateMail}
              tab={tab}
              changePassword={setChangePassword}
              user={me}
              onRowClick={
                isAdminTab ? undefined : (user, e) => setDetailsUser(user, e)
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersPage;
