/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Button,
  DashboardTopBar,
  DashboardTopPortal,
  EmptyState,
} from "shared";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetMe } from "hooks/auth";
import { PACKET_ENUM, ROLES_ENUM, SVG_ENUM } from "enums";

import { AnalyzeType } from "types/AnalyzeTypes";
import { useGetAnalyze } from "hooks/analyzes";
import AnalyzesTable from "features/analyzes/AnalyzesTable/AnalyzesTable";
import { AddAnalyzeModal, AnalyzeInfoModal } from "features/analyzes";

export type AnalyzesTabsType = "all" | "completed" | "my" | "canceled";

const AnalyzesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const linkPage = searchParams.get("page");
  interface ModalManage {
    type: string;
    analyze: AnalyzeType | null;
  }

  const [modalManage, setModalManage] = useState<ModalManage>({
    type: "",
    analyze: null,
  });
  const [page, setPage] = useState(Number(linkPage) || 1);
  const navigate = useNavigate();
  const { data: authorizedUser } = useGetMe();
  const [tab, setTab] = useState<AnalyzesTabsType>(
    authorizedUser?.role === ROLES_ENUM.USER ? "my" : "all"
  );
  const isAdmin = authorizedUser?.role === ROLES_ENUM.ADMIN;

  const { data } = useGetAnalyze({
    page,
    isAdmin: isAdmin,
    status: tab === "all" ? undefined : tab,
  });

  const handleChangeTab = (item: AnalyzesTabsType) => {
    setTab(item);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", "1");
    newSearchParams.set("tab", item);
    setSearchParams(newSearchParams);
  };

  const cleanModalManage = () => {
    setModalManage({ type: "", analyze: null });
    navigate("/dashboard/analyzes");
  };

  useEffect(() => {
    const params = document.location.search;
    const uuid = params.split("&")[0].split("=")[1];
    if (uuid) {
      const analyze = data?.data.find((item) => item.uuid === uuid);
      if (analyze) {
        setModalManage({ type: "companyInfo", analyze: analyze });
      }
    }
  }, [data?.data]);

  return (
    <>
      <AddAnalyzeModal
        isOpen={modalManage.type === "addAnalyze"}
        onClose={() => cleanModalManage()}
      />
      <AnalyzeInfoModal
        isOpen={modalManage.type === "companyInfo"}
        onClose={() => cleanModalManage()}
        analyze={modalManage.analyze}
      />
      <div className="companies">
        <DashboardTopBar>
          <h1 className="dashboard__header">Analiza rynkowa</h1>
          <div className="companies__buttons">
            {!isAdmin && authorizedUser?.package === PACKET_ENUM.PRO ? (
              <>
                <Button
                  className="dashboard__top-button button--rounded button--hide-on-mobile button--transparent companies__meeting"
                  onClick={() =>
                    setModalManage({ type: "addAnalyze", analyze: null })
                  }
                  label="Umów spotkanie"
                  svg={SVG_ENUM.CALENDAR}
                />
                <Button
                  className="dashboard__top-button button--rounded dashboard__fixed-button"
                  onClick={() =>
                    setModalManage({ type: "addAnalyze", analyze: null })
                  }
                  label="Zleć analizę spółki"
                />
              </>
            ) : null}
          </div>
        </DashboardTopBar>
        <div className="dashboard-content__wrapper">
          <div className="dashboard-content">
            <DashboardTopPortal>
              <div className="dashboard__tabs">
                {authorizedUser?.role === ROLES_ENUM.ADMIN ? (
                  <>
                    <span
                      onClick={() => handleChangeTab("all")}
                      className={`dashboard__tab ${
                        tab === "all" ? "dashboard__tab--active" : ""
                      } `}
                    >
                      Wszystkie wnioski
                    </span>
                    <span
                      onClick={() => handleChangeTab("completed")}
                      className={`dashboard__tab ${
                        tab === "completed" ? "dashboard__tab--active" : ""
                      } `}
                    >
                      Wykonane analizy
                    </span>
                    <span
                      onClick={() => handleChangeTab("canceled")}
                      className={`dashboard__tab ${
                        tab === "canceled" ? "dashboard__tab--active" : ""
                      } `}
                    >
                      Odrzucone wnioski
                    </span>
                  </>
                ) : null}
              </div>
            </DashboardTopPortal>
            {data?.data.length ? (
              <AnalyzesTable
                page={page}
                setPage={setPage}
                tab={tab}
                setModalManage={setModalManage}
                data={data}
                onRowClick={(analyze: AnalyzeType, e: any) =>
                  setModalManage({ type: "companyInfo", analyze: analyze })
                }
              />
            ) : !isAdmin && authorizedUser?.package === PACKET_ENUM.PRO ? (
              <EmptyState
                svg={SVG_ENUM.INFO}
                text={
                  <p>
                    W tym miejscu pojawi się lista zleconych analiz rynkowych.
                    Aby zlecić analizę, kliknij w&nbsp;przycisk “zleć analizę
                    rynkową”.
                  </p>
                }
              />
            ) : (
              <EmptyState
                svg={SVG_ENUM.LOCK}
                text={
                  <p>
                    Analizy rynkowe{" "}
                    <span className="fw-600">
                      nie są dostępne w Twoim pakiecie.
                    </span>{" "}
                    Wykup wyższy pakiet, aby odblokować dostęp.
                  </p>
                }
                buttonLabel="Wykup wyższy pakiet"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyzesPage;
