import "./HowItWorks.scss";

type HowItWorksProps = {};

const HowItWorks: React.FC<HowItWorksProps> = () => {
  return (
    <div className="how-it-works layout">
      <div className="how-it-works__left">
        <span />
        <span />
        <span />
      </div>
      <div className="how-it-works__right">
        <h2>
          Jak działa <i>nasza aplikacja?</i>
        </h2>
        <p>
          Aplikacja <span className="fw-600">Reliance Holding</span> to
          narzędzie, które zapewnia dostęp do aktualnych prognoz rynkowych i
          umożliwia wygodne śledzenie trendów na giełdzie. <br />
          <br />
          Dzięki temu inwestorzy mają szansę elastycznie dostosowywać swoje
          portfolio do bieżących zmian, co prowadzi do lepszych wyników
          inwestycyjnych.
        </p>
      </div>
    </div>
  );
};

export default HowItWorks;
