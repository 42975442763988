import { useState } from "react";
import { SideModal } from "shared";
import { UserType } from "types/UsersTypes";
import { InvoiceTable, SharesTable } from "./components";
import "./UserInfoModal.scss";

interface UserInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: UserType | null;
  setEditPersonalData: (user: UserType) => void;
}

interface TabType {
  tab: "invoice" | "payment" | "shares";
}

const UserInfoModal: React.FC<UserInfoModalProps> = ({
  isOpen,
  onClose,
  user,
  setEditPersonalData,
}) => {
  const [tab, setTab] = useState<TabType["tab"]>("shares");
  return (
    <SideModal
      isOpen={isOpen}
      onClose={onClose}
      title="Szczegółowe Informacje"
      subtitle={user?.name}
    >
      <>
        <div className="side-modal__options">
          <span
            className={`side-modal__option ${
              tab === "shares" ? "side-modal__option--active" : ""
            }`}
            onClick={() => setTab("shares")}
          >
            Lista udziałów
          </span>
          <span
            className={`side-modal__option ${
              tab === "invoice" ? "side-modal__option--active" : ""
            }`}
            onClick={() => setTab("invoice")}
          >
            Dane do faktury
          </span>
          <span
            className={`side-modal__option ${
              tab === "payment" ? "side-modal__option--active" : ""
            }`}
            onClick={() => setTab("payment")}
          >
            Płatności
          </span>
        </div>
        {tab === "invoice" && (
          <InvoiceTable user={user} setEditPersonalData={setEditPersonalData} />
        )}
        {tab === "shares" && <SharesTable user={user} />}
      </>
    </SideModal>
  );
};

export default UserInfoModal;
