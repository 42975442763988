import { DashboardTopBar, TabsHandler } from "shared";
import "./ProfilePage.scss";
import { PersonalData, ProfileMenu, AdressData, Payments } from "./components";
import { useState } from "react";
import { useGetMe } from "hooks/auth";
import { isDesktop } from "utils";

export enum profileTabsEnum {
  "personal",
  "location",
  "payments",
}

const ProfilePage = () => {
  const [tab, setTab] = useState<profileTabsEnum | null>(
    isDesktop() ? profileTabsEnum.personal : null
  );
  const { data: authorizedUser } = useGetMe();
  const getTabIndex = () => {
    switch (tab) {
      case profileTabsEnum.personal:
        return 0;
      case profileTabsEnum.location:
        return 1;
      case profileTabsEnum.payments:
        return 2;
      default:
        return -1;
    }
  };

  const handleGoBack = () => {
    setTab(null);
  };

  return (
    <>
      <div className="profile">
        <DashboardTopBar>
          <h1 className="dashboard__header">Profil</h1>
        </DashboardTopBar>
        <div className="dashboard-content__wrapper">
          <div className="dashboard-content">
            <div className="profile__wrapper">
              <ProfileMenu tab={tab} setTab={setTab} />
              <div className="profile__right">
                <TabsHandler currentTab={getTabIndex()}>
                  <PersonalData handleGoBack={handleGoBack} user={authorizedUser} />
                  <AdressData handleGoBack={handleGoBack} user={authorizedUser} />
                  <Payments handleGoBack={handleGoBack} user={authorizedUser} />
                </TabsHandler>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
