import { SideModal, SVG } from "shared";
import "./AnalyzeInfoModal.scss";
import { AnalyzeType } from "types/AnalyzeTypes";
import { AnalyzeForm, AnalyzeTable } from "./components";
import { downloadFile } from "utils";
import { SVG_ENUM } from "enums";

interface AnalyzeInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  analyze: AnalyzeType | null;
}

const AnalyzeInfoModal: React.FC<AnalyzeInfoModalProps> = ({
  isOpen,
  onClose,
  analyze,
}) => {
  return (
    <SideModal
      isOpen={isOpen}
      onClose={onClose}
      title="Szczegółowe informacje"
      subtitle={
        <p>
          Analiza spółki <span className="fw-600">{analyze?.companyName}</span>
        </p>
      }
      className="company-info-modal analyze-info-modal"
    >
      <div>
        <AnalyzeTable analyze={analyze} />
        {analyze?.status === "completed" ? (
          <>
            <p className="analyze-info-modal__analyze">Analiza </p>
            <p className="drop-file-input__file">
              {analyze?.fileId}
              <span
                onClick={() =>
                  downloadFile({
                    name: analyze.file.name,
                    url: analyze.file.url,
                  })
                }
              >
                <SVG type={SVG_ENUM.FILE} />
              </span>
            </p>
          </>
        ) : (
          <AnalyzeForm analyze={analyze} onClose={onClose} />
        )}
      </div>
    </SideModal>
  );
};

export default AnalyzeInfoModal;
