import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { queryClient } from "App";
import axiosInstance from "axiosConfig";
import { TOAST_TYPES_ENUM } from "enums";
import { Toast } from "shared";

export const useSuspend = (
  options?: UseMutationOptions<any, Error, string>
) => {
  return useMutation({
    mutationKey: ["suspend"],
    mutationFn: async (uuid: string) => {
      return axiosInstance
        .patch(`users/suspend/${uuid}`)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Ładowanie...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "suspend",
      });

      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Zaktualizowano użytkownika",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "suspend",
      });
      queryClient.prefetchQuery({ queryKey: ["users"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },

    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas aktualizacji użytkownika",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "suspend",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
