import LogoSVG from "assets/svg/LogoSVG";
import React, { ReactNode } from "react";
import "./LoginWrapper.scss";
import { isDesktop } from "utils";
import { ROUTE_ENUM, SVG_ENUM } from "enums";
import SVG from "shared/SVG/SVG";
import { useNavigate } from "react-router";

interface LoginWrapperProps {
  children: ReactNode;
  className?: string;
}

const LoginWrapper: React.FC<LoginWrapperProps> = ({
  children,
  className = "",
}) => {
  const navigate = useNavigate();
  return (
    <main className={`login-wrapper ${className}`}>
      <span onClick={() => navigate(ROUTE_ENUM.HOME)}>
        <LogoSVG className="login-wrapper__logo" />
      </span>
      <div className="login-wrapper__content">{children}</div>
      <SVG
        type={
          isDesktop() ? SVG_ENUM.LOGIN_BG_DESKTOP : SVG_ENUM.LOGIN_BG_MOBILE
        }
        className="login-wrapper__background"
      />
    </main>
  );
};

export default LoginWrapper;
