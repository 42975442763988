import moment from "moment";
import React from "react";
import { AnalyzeType } from "types/AnalyzeTypes";

interface AnalyzeTableProps {
  analyze: AnalyzeType | null;
}

const AnalyzeTable: React.FC<AnalyzeTableProps> = ({ analyze }) => {
  return (
    <table className="side-modal__table">
      <tr>
        <td className="side-modal__table-label">Nazwa spółki:</td>
        <td className="side-modal__table-cell">
          {analyze?.companyName || "-"}
        </td>
      </tr>
      <tr>
        <td className="side-modal__table-label">NIP spółki:</td>
        <td className="side-modal__table-cell">{analyze?.nip || "-"}</td>
      </tr>
      <tr>
        <td className="side-modal__table-label">Data zlecenia:</td>
        <td className="side-modal__table-cell">
          {moment(analyze?.createdAt).format("DD/MM/YYYY") || "-"}
        </td>
      </tr>
      <tr>
        <td className="side-modal__table-label">Zlecający:</td>
        <td className="side-modal__table-cell">{analyze?.user?.name || "-"}</td>
      </tr>
    </table>
  );
};

export default AnalyzeTable;
