import { useEffect } from "react";
import "./PasswordValidation.scss";
import SVG from "shared/SVG/SVG";
import { SVG_ENUM } from "enums";

type ValidationItemProps = {
  correct: boolean;
  label: string;
};

function ValidationItem({ correct, label }: ValidationItemProps) {
  return (
    <div className="validation-item">
      <SVG type={correct ? SVG_ENUM.ACCEPT_GREEN : SVG_ENUM.CLOSE_CIRCLE} />
      <p>{label}</p>
    </div>
  );
}

type PasswordValidationProps = {
  password: string;
  className?: string;
  setIsValid?: (isValid: boolean) => void;
  show: boolean;
};

function PasswordValidation({
  password,
  className = "",
  setIsValid,
  show,
}: PasswordValidationProps) {
  useEffect(() => {
    if (setIsValid) {
      setIsValid(
        /[A-Z]/.test(password) &&
          /[a-z]/.test(password) &&
          /[0-9]/.test(password) &&
          password.replace(" ", "").length >= 8
      );
    }
  }, [password, setIsValid]);
  return (
    <div
      className={`validation-item__wrapper password-validation ${
        show ? "password-validation--show" : ""
      }`}
    >
      <ValidationItem
        correct={/[A-Z]/.test(password)}
        label={"1 duża litera"}
      />
      <ValidationItem
        correct={/[a-z]/.test(password)}
        label={"1 mała litera"}
      />
      {/* <ValidationItem correct={/[!@#$%^&*(),.?":{}|<>]/.test(password)} label={'1 znak specjalny '} /> */}
      <ValidationItem correct={/[0-9]/.test(password)} label={"1 cyfra"} />
      <ValidationItem
        correct={password.replace(" ", "").length >= 8}
        label={"Minimum 8 znaków"}
      />
    </div>
  );
}
export default PasswordValidation;
