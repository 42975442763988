import React from "react";
import {
  Button,
  LoginWrapper,
} from "shared";
import { ROUTE_ENUM } from "enums";
import { useNavigate } from "react-router";

type LinkExpiredPageProps = {};

const LinkExpiredPage: React.FC<LinkExpiredPageProps> = () => {
    const navigate = useNavigate();
    
  return (
    <LoginWrapper>
          <h1 className="login-wrapper__header">Ten link wygasł</h1>
          <p className="login-wrapper__subtitle">
          Skontaktuj się z administratorem w celu przesłania nowego linku aktywacyjnego.
          </p>
          <Button
            label="Skontaktuj się"
            className="button--black button--rounded login-wrapper__submit login-wrapper__submit--no-margin button--wide"
            onClick={() => {
              navigate(ROUTE_ENUM.LOGIN);
            }}
          />
    </LoginWrapper>
  );
};

export default LinkExpiredPage;
