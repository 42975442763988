import { SVG_ENUM } from "enums";
import "./DashboardTopBar.scss";
import { SVG } from "shared";
import { isDesktop } from "utils";

interface DashboardTopBarType {
  children: React.ReactNode;
}

const DashboardTopBar = ({ children }: DashboardTopBarType) => {
  return (
    <>
      <div className="dtb__wrapper">
        <SVG
          type={
            isDesktop()
              ? SVG_ENUM.DASHBOARD_BG_DESKTOP
              : SVG_ENUM.DASHBOARD_BG_MOBILE
          }
          className="dtb__bg"
        />
        <div className="dtb">{children}</div>
      </div>
    </>
  );
};

export default DashboardTopBar;
