// TabsHandler.tsx
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./TabsHandler.scss";

type TabsHandlerProps = {
  currentTab: number;
  children: React.ReactNode[];
};

const TabsHandler: React.FC<TabsHandlerProps> = ({ currentTab, children }) => {
  return (
    <div className="tabs-container">
      <TransitionGroup>
        {React.Children.map(children, (child, index) =>
          currentTab === index ? (
            <CSSTransition key={index} timeout={300} classNames="fade">
              <div className="tab-content">{child}</div>
            </CSSTransition>
          ) : null
        )}
      </TransitionGroup>
    </div>
  );
};

export default TabsHandler;
