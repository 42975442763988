import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosConfig from "axiosConfig";
import { EmailType } from "types/AuthTypes";
import { Toast } from "shared";
import { TOAST_TYPES_ENUM } from "enums";

const resetPassword = async (credentials: EmailType): Promise<any> => {
  const response = await axiosConfig.post(`/auth/recover`, credentials);
  return response.data;
};

export const useResetPassword = (
  options?: UseMutationOptions<any, Error, EmailType>
) => {
  return useMutation({
    mutationKey: ["reset-password"],
    mutationFn: async (credentials: EmailType) => {
      return resetPassword(credentials);
    },
    onMutate: (variables) => {
      Toast({
        message: "Logowanie...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "reset-password",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Link do resetu hasła został wysłany",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "reset-password",
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Błąd resetu hasła",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "reset-password",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
