import { SVG_ENUM } from "enums";
import React, { useEffect, useState } from "react";
import { SVG } from "shared";

function SvgTestPage() {
  const [isBlack, setIsBlack] = useState(false);
  useEffect(() => {
    const mainWrapper: any = document.querySelector(".svg-list");
    const p: any = document.querySelectorAll("p");
    if (isBlack) {
      mainWrapper.style.backgroundColor = "black";
      p.forEach((el: any) => {
        el.style.color = "white";
      });
    } else {
      mainWrapper.style.backgroundColor = "white";
      p.forEach((el: any) => {
        el.style.color = "black";
      });
    }
  }, [isBlack]);
  return (
    <ol className="svg-list">
      <div style={{ position: "fixed", right: 0, top: 24 }}>
        <button onClick={() => setIsBlack(!isBlack)}>Change color</button>
      </div>
      {Object.values(SVG_ENUM).map((svgType: any) => (
        <li key={svgType} style={{ marginBottom: 24 }}>
          <p style={{ marginBottom: 10 }}>{svgType}</p>

          <SVG type={svgType} />
        </li>
      ))}
    </ol>
  );
}
export default SvgTestPage;
