import { UserStatus, ContextMenu, SVG, CompanyStatus } from "shared";
import { SVG_ENUM } from "enums";
import { UserType } from "types/UsersTypes";
import {
  CompanyMyType,
  CompanyStatusType,
  CompanyType,
} from "types/CompanyTypes";
import moment from "moment";
import { getCurrencyFromUuid, getIndustryFromUuid } from "utils";

const items = ({
  setEditCompany,
  handleStatusChange,
  setModalManage,
  isAdmin,
}: {
  setEditCompany: (company: CompanyType) => void;
  handleStatusChange: (
    company: CompanyType,
    status: CompanyStatusType["status"]
  ) => void;
  setModalManage: ({
    type,
    company,
  }: {
    type: string;
    company: CompanyType | CompanyMyType | null;
  }) => void;
  isAdmin: boolean;
}) => {
  return (company: CompanyType) => {
    const actions = [];

    if (company.status !== "withdrawn") {
      actions.push({
        name: "Edytuj",
        action: () => setEditCompany(company),
        svg: SVG_ENUM.EDIT,
      });

      if (company.status !== "hidden") {
        actions.push({
          name: "Ukryj",
          action: () => handleStatusChange(company, "hidden"),
          svg: SVG_ENUM.CROSSED_EYE,
        });
      } else {
        actions.push({
          name: "Pokaż",
          action: () => handleStatusChange(company, "active"),
          svg: SVG_ENUM.EYE,
        });
      }
      actions.push({
        name: "Wycofaj",
        action: () => handleStatusChange(company, "withdrawn"),
        svg: SVG_ENUM.STOP_SIGN,
      });
    }

    if (company.status === "withdrawn") {
      actions.push({
        name: "Przywróć",
        action: () => handleStatusChange(company, "active"),
        svg: SVG_ENUM.ARROW_UNDO,
      });
    }

    if (company.status === "active" && isAdmin) {
      actions.push({
        name: "Dodaj klienta",
        action: () => setModalManage({ company, type: "addClient" }),
        svg: SVG_ENUM.USER,
      });
    }

    return actions;
  };
};

const userItems = () => {
  return (user: UserType) => {
    const actions = [];

    if (user.status !== "inactive") {
      actions.push({
        name: user.status === "suspended" ? "Przywróć" : "Zawieś",
        action: () => console.log(user),
        svg: user.status === "suspended" ? SVG_ENUM.ARROW_UNDO : SVG_ENUM.LOCK,
      });
    }
    return actions;
  };
};

export const mobileColumns = () => [
  {
    width: "calc(50% - 10px)",
    render: (values: any) => (
      <div className="users-table-mobile__name">{`${values.name}`}</div>
    ),
  },
  {
    dataIndex: "status",
    key: "status",
    width: 100,
    render: (value: string) => <UserStatus status={value} />,
  },
  {
    width: 88,
    render: (item: UserType) => (
      <ContextMenu data={item} items={userItems()(item)}>
        <SVG type={SVG_ENUM.DOTS} />
      </ContextMenu>
    ),
  },
];

export const desktopColumns = ({
  setEditCompany,
  handleStatusChange,
  isAdmin = false,
  setModalManage,
}: {
  setEditCompany: (company: CompanyType) => void;
  handleStatusChange: (
    company: CompanyType,
    status: CompanyStatusType["status"]
  ) => void;
  isAdmin?: boolean;
  setModalManage: ({
    type,
    company,
  }: {
    type: string;
    company: CompanyType | CompanyMyType | null;
  }) => void;
}) => {
  let result = [
    {
      title: "Nazwa spółki",
      key: "index",
      width: 40,
      render: (value: any, record: CompanyType, index: number) => (
        <div
          data-tooltip-id="company-tooltip"
          data-tooltip-content={record.description}
          className="companies-table__info"
        >
          <SVG type={SVG_ENUM.INFO} />
        </div>
      ),
    },
    {
      dataIndex: "name",
      key: "name",
      width: 120,
    },
    {
      title: "Branża",
      width: 100,
      render: (value: any, record: CompanyType, index: number) => {
        return `${getIndustryFromUuid(record.industryUuid)}`;
      },
    },
    {
      title: "Max wartość akcji",
      width: 130,
      render: (value: any, record: CompanyType, index: number) =>
        `${record.maxShareValues} ${getCurrencyFromUuid(record.currencyUuid)}`,
    },
    {
      title: "Dostępna wartość akcji",
      width: 150,
      render: (value: any, record: CompanyType, index: number) =>
        `${record.availableShares} ${getCurrencyFromUuid(record.currencyUuid)}`,
    },
    {
      title: "Waluta",
      width: 60,
      render: (value: any, record: CompanyType, index: number) =>
        `${getCurrencyFromUuid(record.currencyUuid)}`,
    },
    {
      title: "Wycena spółki",
      width: 120,
      render: (value: any, record: CompanyType, index: number) =>
        `${record.valuation} ${getCurrencyFromUuid(record.currencyUuid)}`,
    },
    {
      title: "Data publikacji na giełdzie",
      width: 170,
      render: (value: any, record: CompanyType, index: number) =>
        record.publicationDate
          ? `${moment(record.publicationDate).format("DD/MM/YYYY")}r.`
          : null,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (value: CompanyStatusType["status"]) => (
        <CompanyStatus status={value} />
      ),
    },

    {
      width: 88,
      render: (item: CompanyType) => (
        <ContextMenu
          data={item}
          items={items({
            setEditCompany,
            handleStatusChange,
            setModalManage,
            isAdmin,
          })(item)}
        >
          <SVG type={SVG_ENUM.DOTS} />
        </ContextMenu>
      ),
    },
  ];

  if (!isAdmin) {
    result.splice(result.length - 2, 1);
  }
  return result;
};
