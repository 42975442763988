import { Footer, TopNavigation } from "shared";

type PageWrapperProps = {
  children: React.ReactNode;
};

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  return (
    <>
      <TopNavigation />
      {children}
      <Footer />
    </>
  );
};

export default PageWrapper;
