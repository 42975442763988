import { SVG } from "shared";
import { SVG_ENUM } from "enums";

import { ClientType } from "types/ClientType";
import { getCurrencyFromUuid } from "utils";
import { SharesByCompanyType } from "types/SharesTypes";
import { CompanyMyType, CompanyType } from "types/CompanyTypes";
import moment from "moment";

export const mobileColumns = ({ company }: { company: CompanyType | null }) => [
  {
    width: "calc(100% - 20px)",
    render: (data: ClientType) => (
      <table className="side-modal__table">
        <tr>
          <td className="side-modal__table-label">Klient:</td>
          <td className="side-modal__table-cell">{data?.name || "-"}</td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Wykupione udziały:</td>
          <td className="side-modal__table-cell">{data?.shares || "-"}</td>
        </tr>
        <tr>
          <td className="side-modal__table-label">
            Kwota wykupionych udziałów:
          </td>
          <td className="side-modal__table-cell">{data?.sharesValue || "-"}</td>
        </tr>
        <tr>
          <td className="side-modal__table-label">Waluta:</td>
          <td className="side-modal__table-cell">
            {company ? getCurrencyFromUuid(company?.currencyUuid) : null}
          </td>
        </tr>
      </table>
    ),
  },

  {
    width: 88,
    render: () => <SVG type={SVG_ENUM.FILE} />,
  },
];

export const desktopColumns = ({
  company,
}: {
  company: CompanyType | null;
}) => [
  {
    title: "Klient",
    dataIndex: "name",
    key: "name",
    width: 150,
  },
  {
    title: "Wykupione udziały",
    width: 150,
    dataIndex: "sharesCount",
    key: "sharesCount",
  },
  {
    title: "Kwota wykupionych udziałów",
    width: 150,
    dataIndex: "sharesValue",
    key: "sharesValue",
  },
  {
    title: "Data",
    width: 150,
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (item: Date) => moment(item).format("DD/MM/YYYY"),
  },
  {
    title: "Waluta",
    width: 40,
    render: (item: SharesByCompanyType) => {
      return company ? getCurrencyFromUuid(company?.currencyUuid) : null;
    },
  },
  // {
  //   width: 40,
  //   render: (item: SharesByCompanyType) =>
  //     item.invoice ? (
  //       <span
  //         className="clients-table__download"
  //         onClick={() => downloadFile(item.invoice)}
  //       >
  //         <SVG type={SVG_ENUM.FILE} />
  //       </span>
  //     ) : null,
  // },
];

export const summaryColumns = (item: CompanyType) => [
  {
    key: "type",
    width: 150,
    render: () => {
      return "Razem";
    },
  },
  {
    width: 150,
    render: (item: CompanyMyType) => item?.sharesCount,
  },
  {
    width: 150,
    render: (item: CompanyMyType) =>
      item?.sharesValue
        ? item?.sharesValue + " " + getCurrencyFromUuid(item?.currencyUuid)
        : "-",
  },
  {
    width: 150,
  },
  {
    width: 80,
  },
];
