import React, { useEffect, useState } from "react";
import { EnterCode, LoginWrapper } from "shared";
import "./EnterCodePage.scss";
import { Formik, Form } from "formik";
import { ROUTE_ENUM, VALIDATION_ENUM } from "enums";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useLoginSms } from "hooks/auth";

type EnterCodePageProps = {};

const EnterCodePage: React.FC<EnterCodePageProps> = () => {
  const [code, setCode] = useState<string[]>(["", "", "", ""]);

  const navigate = useNavigate();

  const { mutate } = useLoginSms({
    onSuccess: () => {
      if (sessionStorage.getItem("destinationPath")) {
        navigate(sessionStorage.getItem("destinationPath") as string);
        sessionStorage.removeItem("destinationPath");
      } else {
        navigate(ROUTE_ENUM.COMPANIES);
      }
    },
    onError: (error: Error) => {
      console.error("Błąd podczas logowania:", error.message);
    },
  });

  const validation = () =>
    Yup.object().shape({
      smsCode: Yup.string().required(VALIDATION_ENUM.REQUIRED),
    });

  const initialValues = {
    smsCode: "",
  };

  const onSubmit = (values: typeof initialValues) => {
    const smsCode = code.join("");
    mutate({ smsCode });
  };

  useEffect(() => {
    if (code.join("").length === 4) {
      onSubmit({ smsCode: code.join("") });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <LoginWrapper className="enter-code-page">
      <h1 className="login-wrapper__header">Wprowadź kod dostępu</h1>
      <p className="login-wrapper__subtitle">
        Wprowadź 4-cyfrowy kod, który przesłaliśmy Ci w wiadomości SMS.
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
      >
        {({ errors, values, handleSubmit }) => (
          <Form>
            <EnterCode setCode={setCode} code={code} />

            <p
              className="login-wrapper__anchor enter-code-page__anchor"
              onClick={() => navigate(ROUTE_ENUM.LOGIN)}
            >
              Nie otrzymałeś kodu? <span> Wyślij ponownie</span>
            </p>
          </Form>
        )}
      </Formik>
    </LoginWrapper>
  );
};

export default EnterCodePage;
