import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  LoginWrapper,
  PasswordStrengthChecker,
  PasswordValidation,
} from "shared";
import "./CreateNewPasswordPage.scss";
import { Field, Formik, Form } from "formik";
import { ROUTE_ENUM, VALIDATION_ENUM } from "enums";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import {
  useCheckToken,
  useCreateNewPassword,
} from "hooks/auth";

type CreateNewPasswordPageProps = {};

const CreateNewPasswordPage: React.FC<CreateNewPasswordPageProps> = () => {
  const [showValidation, setShowValidation] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token") || "";

  const { mutate } = useCreateNewPassword({
    onSuccess: () => {
      setIsDone(true);
    },
    onError: (error: Error) => {
      console.error("Błąd podczas rejestracji:", error.message);
    },
  });

  const validation = () =>
    Yup.object().shape({
      password: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .min(8, VALIDATION_ENUM.MIN_8),
      confirmPassword: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .oneOf([Yup.ref("password")], VALIDATION_ENUM.CONFIRM_PASSWORD),
    });

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const { mutate: mutateCheckToken } = useCheckToken({
    onError: (error: Error) => {
      navigate(ROUTE_ENUM.LINK_EXPIRED);
    },
  });

  useEffect(() => {
    const checkToken = () => {
      if (token) {
        mutateCheckToken({ token });
      }
    };
    checkToken();
    // eslint-disable-next-line
  }, [token]);

  const onSubmit = (values: typeof initialValues) => {
    mutate({ newPassword: values.password, resetToken: token });
  };

  return (
    <LoginWrapper className="register-admin">
      {isDone ? (
        <>
          {" "}
          <h1 className="login-wrapper__header">Hasło zostało zmienione</h1>
          <p className="login-wrapper__subtitle">
            Możesz teraz zalogować się za pomocą nowego hasła
          </p>
          <Button
            label="Zaloguj się"
            className="button--black button--rounded login-wrapper__submit"
            onClick={() => {
              navigate(ROUTE_ENUM.LOGIN_ADMIN);
            }}
          />
        </>
      ) : (
        <>
          <h1 className="login-wrapper__header">Utwórz nowe hasło</h1>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={validation()}
          >
            {({ errors, values, handleSubmit }) => (
              <Form>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  label={"Hasło"}
                  as={Input}
                  onFocus={() => setShowValidation(true)}
                  onBlur={() => isPasswordValid && setShowValidation(false)}
                />
                <PasswordStrengthChecker
                  password={values.password}
                  isHidden={!showValidation}
                />
                <PasswordValidation
                  show={showValidation}
                  password={values.password}
                  setIsValid={(value: boolean) => setIsPasswordValid(value)}
                />
                <Field
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  label={"Powtórz hasło"}
                  as={Input}
                />

                <Button
                  label="Potwierdź"
                  className="button--black button--rounded login-wrapper__submit button--wide"
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </Form>
            )}
          </Formik>
        </>
      )}
    </LoginWrapper>
  );
};

export default CreateNewPasswordPage;
