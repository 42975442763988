import React, { useEffect, useRef } from "react";
import {
  Button,
  Checkbox,
  Input,
  LoginWrapper,
  PhoneWithPrefix,
  SelectComponent,
} from "shared";
import "./RegisterPage.scss";
import { Field, Formik, Form } from "formik";
import { ROUTE_ENUM, VALIDATION_ENUM } from "enums";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useGetPhonePrefixes } from "hooks/phone";
import { useCreateAccount } from "hooks/users";

type RegisterPageProps = {};

const RegisterPage: React.FC<RegisterPageProps> = () => {
  const { data: phonePrefixes } = useGetPhonePrefixes();
  const navigate = useNavigate();
  const formRef = useRef<any>(null);
  const { mutate } = useCreateAccount({
    onSuccess: () => {
      navigate(ROUTE_ENUM.LOGIN);
    },
    onError: (error: Error) => {
      console.error("Błąd podczas rejestracji:", error.message);
    },
  });

  const validation = () =>
    Yup.object().shape({
      name: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      phonePrefixUuid: Yup.string().required(VALIDATION_ENUM.REQUIRED),
      phoneNumber: Yup.string()
        .required(VALIDATION_ENUM.REQUIRED)
        .test("is-digitals-only", VALIDATION_ENUM.NUMBER, (value) => {
          return /^\d+$/.test(value);
        }),
      acceptance: Yup.boolean().oneOf([true], VALIDATION_ENUM.REQUIRED),
    });

  const initialValues = {
    name: "",
    phonePrefixUuid: "",
    phoneNumber: "",
    acceptance: false,
  };

  const onSubmit = (values: typeof initialValues) => {
    mutate(values);
  };

  useEffect(() => {
    if (phonePrefixes.length && formRef.current) {
      const pl = phonePrefixes.find((prefix) => prefix.label === "+48");
      formRef.current.setFieldValue("phonePrefixUuid", pl?.value);
    }
  }, [phonePrefixes]);

  return (
    <LoginWrapper>
      <h1 className="login-wrapper__header">Utwórz konto</h1>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validationSchema={validation()}
        innerRef={formRef}
      >
        {({ errors, values, handleSubmit }) => (
          <Form>
            <Field
              type="text"
              id="name"
              name="name"
              label={"Imię i nazwisko"}
              as={Input}
            />
            <PhoneWithPrefix>
              <Field
                label="Telefon"
                name="phonePrefixUuid"
                id="phonePrefixUuid"
                as={SelectComponent}
                options={phonePrefixes}
              />
              <Field
                name="phoneNumber"
                type="text"
                id="phoneNumber"
                as={Input}
              />
            </PhoneWithPrefix>
            <Field
              id="acceptance"
              name="acceptance"
              wrapperClassName="login-wrapper__acceptance"
              label={
                <p>
                  Lorem ipsum dolor sit amet consectetur. Nisl risus porttitor
                  morbi eget dignissim sapien eu posuere. Habitant amet aliquam
                  augue massa vel varius
                </p>
              }
              as={Checkbox}
            />

            <Button
              label="Zarejestruj się"
              className="button--black button--rounded login-wrapper__submit button--wide"
              onClick={() => {
                handleSubmit();
              }}
            />
            <p
              className="login-wrapper__anchor"
              onClick={() => navigate(ROUTE_ENUM.LOGIN)}
            >
              Masz już konto? <span> Zaloguj się</span>
            </p>
          </Form>
        )}
      </Formik>
    </LoginWrapper>
  );
};

export default RegisterPage;
