import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { queryClient } from "App";
import axiosConfig from "axiosConfig";
import { TOAST_TYPES_ENUM } from "enums";
import { Toast } from "shared";
import { CompanyDeleteClientType } from "types/CompanyTypes";

export const useDeleteCompanyClient = (
  options?: UseMutationOptions<any, Error, CompanyDeleteClientType>,
  withoutToast?: boolean
) => {
  return useMutation({
    mutationKey: ["companyDelete"],
    mutationFn: async (credentials: CompanyDeleteClientType) => {
      return axiosConfig
        .delete(`/companies/client/${credentials.shareUuid}`)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      if (!withoutToast) {
        Toast({
          message: "Ładowanie...",
          type: TOAST_TYPES_ENUM.LOADING,
          id: "companyDelete",
        });
      }
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      if (!withoutToast) {
        Toast({
          message: "Pomyślnie Usunięto spółkę",
          type: TOAST_TYPES_ENUM.SUCCESS,
          id: "companyDelete",
        });
      }
      queryClient.invalidateQueries({ queryKey: ["companies/my"] });
      queryClient.invalidateQueries({ queryKey: ["shares/company"] });

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },

    onError: (error, variables, context) => {
      if (!withoutToast) {
        Toast({
          message: "Błąd podczas usuwania spółki",
          type: TOAST_TYPES_ENUM.ERROR,
          id: "companyDelete",
        });
      }
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
