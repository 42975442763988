import { ROLES_ENUM, ROUTE_ENUM } from "enums";
import { NavigateFunction } from "react-router";
import { UserType } from "types/UsersTypes";

export default function checkIfLogged(
  authorizedUser: UserType | undefined,
  navigate: NavigateFunction
): void {
  if (authorizedUser) {
    if (authorizedUser.role === ROLES_ENUM.ADMIN) {
      navigate(ROUTE_ENUM.USERS);
    } else {
      navigate(ROUTE_ENUM.COMPANIES);
    }
  }
}

