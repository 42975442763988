import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosConfig from "axiosConfig";
import { ConfirmEmailType } from "types/AuthTypes";

const checkToken = async (credentials: ConfirmEmailType): Promise<any> => {
  const response = await axiosConfig.get(
    `/auth/valid/token?token=${credentials.token}`
  );
  return response.data;
};

export const useCheckToken = (
  options?: UseMutationOptions<any, Error, ConfirmEmailType>
) => {
  return useMutation({
    mutationKey: ["check-token"],
    mutationFn: async (credentials: ConfirmEmailType) => {
      return checkToken(credentials);
    },
    onSuccess: (data, variables, context) => {
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
