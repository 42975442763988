// PopupModal.tsx
import React, { FC, ReactNode } from "react";
import { Transition } from "react-transition-group";
import "./PopupModal.scss";

interface PopupModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const PopupModal: FC<PopupModalProps> = ({ isOpen, onClose, children }) => {
  const duration = 300;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
  };

  const transitionStyles: { [key: string]: React.CSSProperties } = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <Transition in={isOpen} timeout={duration} unmountOnExit>
      {(state) => (
        <div
          className="popup-modal-overlay"
          onClick={onClose}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <div className="popup-modal" onClick={(e) => e.stopPropagation()}>
            <div className="popup-modal-content">
              {children}
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default PopupModal;
