import { SVG_ENUM } from "enums";
import { SVG } from "shared";
import { TransactionTypeAdmin } from "types/TransactionsTypes";
import { downloadFile, getCurrencyFromUuid } from "utils";

export const desktopColumns = () => [
  {
    title: "Nazwa spółki",
    width: 250,
    render: (item: TransactionTypeAdmin) => {
      console.log(item);
      return (
        <span className="clients-table__name">{item?.share?.company?.name}</span>
      );
    },
  },

  {
    title: "Udziały",
    width: 150,
    render: (item: TransactionTypeAdmin) => (
      <span className="clients-table__shares">
        {item?.sharesValue
          ? `${item?.sharesValue} ${getCurrencyFromUuid(
              item?.share?.company?.currencyUuid
            )}`
          : "-"}
      </span>
    ),
  },
  {
    title: "Umowa",
    width: 40,
    render: (item: TransactionTypeAdmin) => {
      return item?.file ? (
        <span
          className="clients-table__download"
          onClick={() => downloadFile({ name: item.file.name, url: item.file.url })}
        >
          <SVG type={SVG_ENUM.FILE} />
        </span>
      ) : null;
    },
  },
];
