import { SVG } from "shared";
import "./News.scss";
import { SVG_ENUM } from "enums";

type NewsProps = {};

const News: React.FC<NewsProps> = () => {
  return (
    <div className="news">
      <SVG type={SVG_ENUM.NEWS_BARS} className="news__bg" />
      <div className="layout">
        <h2>
          <i>Nasze aktualności</i>, Twoje korzyści
        </h2>
        <div className="news__wrapper">
          <div className="news__card">
            <span className="news__card-icon">
              <SVG type={SVG_ENUM.NOTIFICATION_TEXT} />
            </span>
            <h3 className="news__card-title">
              Bieżące informacje na wyciągnięcie ręki
            </h3>
            <p>
              Zyskaj dostęp do aktualnych danych o spółkach,  które znajdują się
              w portfelu inwestycyjnym Reliance.
            </p>
          </div>

          <div className="news__card">
            <span className="news__card-icon">
              <SVG type={SVG_ENUM.WALLET} />
            </span>
            <h3 className="news__card-title">
              Elastyczne zarządzanie portfelem
            </h3>
            <p>
              Dostosowuj swoje inwestycje do aktualnych trendów rynkowych i
              osiągaj najlepsze wyniki. 
            </p>
          </div>

          <div className="news__card">
            <span className="news__card-icon">
              <SVG type={SVG_ENUM.LINE_CHART_UP} />
            </span>
            <h3 className="news__card-title">Szybka reakcja na zmiany</h3>
            <p>
              Śledź najnowsze prognozy rynkowe i&nbsp;podejmuj skuteczne decyzje
              finansowe.
            </p>
          </div>

          <div className="news__card">
            <span className="news__card-icon">
              <SVG type={SVG_ENUM.PRESENTATION_CHART} />
            </span>
            <h3 className="news__card-title">Dogłębna analiza spółek</h3>
            <p>
              Zyskaj dostęp do szczegółowych analiz wybranych spółek, które
              pomogą w ocenie potencjału inwestycyjnego i planowaniu strategii.
            </p>
          </div>

          <div className="news__card">
            <span className="news__card-icon">
              <SVG type={SVG_ENUM.USERS_CHECK} />
            </span>
            <h3 className="news__card-title">Wsparcie ekspertów</h3>
            <p>
              Skorzystaj z pomocy naszych specjalistów gotowych odpowiedzieć
              na Twoje pytania i pomóc w pełnym wykorzystaniu możliwości
              aplikacji.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
