import "./LargeSwitch.scss";

type P = {
  onChange: (value: any) => void;
  state: string;
  options: { value: string; label: string }[];
  className?: string;
};

const LargeSwitch = ({ onChange, state, options, className = "" }: P) => {
  return (
    <>
      <div className={`large-switch ${className}`}>
        {options.map((option) => (
          <span
            key={option.value}
            className={`large-switch__option ${
              state === option.value ? "large-switch__option--active" : ""
            }`}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </span>
        ))}
      </div>
    </>
  );
};

export default LargeSwitch;
