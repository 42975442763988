import { VALIDATION_ENUM } from "enums";
import { Field, Formik, Form } from "formik";
import { useStoreTransaction } from "hooks/transactions";
import moment from "moment";
import {
  Button,
  CalendarInput,
  DropFileInput,
  FullscreenModal,
  Input,
} from "shared";
import { CompanyMyType } from "types/CompanyTypes";
import { TransactionStoreType } from "types/TransactionsTypes";
import { getCurrencyFromUuid, getCurrencySymbol } from "utils";
import * as Yup from "yup";

interface SellSharesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  company: CompanyMyType | null;
}

const SellSharesModal: React.FC<SellSharesModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  company,
}) => {
  const { mutateAsync: storeTransaction } = useStoreTransaction();

  const initialValues = {
    sharesCount: null as number | null,
    sharesValue: null as number | null,
    invoice: null,
    completedAt: moment().format("YYYY-MM-DD"),
  };

  const onSubmit = async (values: TransactionStoreType) => {
    company &&
      (await storeTransaction(
        { ...values, shareUuid: company?.sharesUuid, type: "sell" },
        {
          onSuccess: () => {
            onSuccess && onSuccess();
            onClose();
          },
        }
      ));

    onClose();
  };

  const validation = () =>
    Yup.object().shape({
      sharesCount: Yup.number().required(VALIDATION_ENUM.REQUIRED),
    });

  return (
    <FullscreenModal isOpen={isOpen} onClose={onClose}>
      <h2 className="fullscreen-modal__header">Zaznacz sprzedaż</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => onSubmit(values)}
        validationSchema={validation()}
        enableReinitialize
      >
        {({ errors, values, handleSubmit }) => (
          <Form className="m-width-100 fullscreen-modal__select-margin">
            <Field
              type="number"
              id="sharesCount"
              name="sharesCount"
              label={"Ilość sprzedanych udziałów"}
              as={Input}
            />
            <div className="input--currency">
              <Field
                type="number"
                id="sharesValue"
                name="sharesValue"
                label={"Wartość sprzedanych udziałów (opcjonalne)"}
                as={Input}
              />
              <span>
                {company?.currencyUuid
                  ? getCurrencySymbol(
                      getCurrencyFromUuid(company?.currencyUuid)
                    )
                  : null}
              </span>
            </div>
            <Field
              type="date"
              id="completedAt"
              name="completedAt"
              label={"Data transakcji"}
              component={CalendarInput}
              errors={errors}
            />

            <Field
              label="Umowa (opcjonalne)"
              name="invoice"
              id="invoice"
              as={DropFileInput}
              accept=".pdf"
            />

            <div className="fullscreen-modal__buttons">
              <Button
                label="Anuluj"
                onClick={onClose}
                className="button--stroke button--rounded"
              />
              <Button
                label={"Potwierdź"}
                type="submit"
                onClick={() => null}
                className=" button--rounded button--black"
              />
            </div>
          </Form>
        )}
      </Formik>
    </FullscreenModal>
  );
};

export default SellSharesModal;
