/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FieldProps } from "formik";
import "./textarea.scss";

interface InputProps {
  label: string;
  name: string;
  type: string;

  placeholder?: string;
  className?: string;
  disabled?: boolean;
  secondValue?: string;
  multi?: boolean;
  treshold?: number;
  removable?: boolean;
  maxLength?: number;
  id?: string;
  inputMode?: string;
  pattern?: string;
  variant?: string;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (value: any) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data: any) => void;
  onFocus: any;
  onBlur?: any;
}

function Textarea({
  label,
  className = "",
  pattern,
  name,
  type,
  form,
  errors = {},
  field,
  onChange,
  treshold = 500,
  id,
  maxLength,
}: InputProps & FieldProps) {
  const [value, setValue] = useState<string>(field?.value);
  const [debouncedValues, setDebouncedValues] = useState<string>(field?.value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValues(value);
    }, treshold);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [value]);

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  useEffect(() => {
    if (debouncedValues) {
      form.setFieldValue(field.name, value);
    }
  }, [debouncedValues]);

  return (
    <div
      id={id}
      className={`textarea__wrapper ${className} ${
        form.touched[field.name] && errors[field.name]
          ? "textarea__wrapper--error"
          : ""
      }`}
    >
      <label className="textarea__label" htmlFor={field.name}>
        {label}{" "}
      </label>
      <textarea
        {...field}
        name={field.name}
        className={`textarea ${
          form.touched[field.name] && errors[field.name]
            ? "textarea--border-error"
            : ""
        }`}
        value={field.value}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.value);
          setValue(e.target.value);
          onChange && onChange(e.target.value);
        }}
        pattern={pattern}
        id={name}
        type={type}
        maxLength={maxLength || undefined}
      />
      {errors && form.touched && (
        <div className="textarea__error">
          {form.touched[field.name] && <span>{errors[field.name]}</span>}
        </div>
      )}
    </div>
  );
}

export default Textarea;
