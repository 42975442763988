import SVG from "shared/SVG";
import "./Footer.scss";
import { ROUTE_ENUM, SVG_ENUM } from "enums";
import { useNavigate } from "react-router";

type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="layout">
        <span
          className="footer__logo"
          onClick={() => navigate(ROUTE_ENUM.HOME)}
        >
          <SVG type={SVG_ENUM.LOGO} />
        </span>
      </div>
    </footer>
  );
};

export default Footer;
