import { CountriesType } from "types/CountriesTypes";
import { queryClient } from "App";
const getCurrencyFromUuid = (uuid: string): string => {
  const currencies = queryClient.getQueryData<[CountriesType]>([
    "countries/currency",
  ]);
  const currency =
    currencies &&
    currencies.find((currencyItem) => currencyItem.value === uuid);
  return currency ? currency.label : "";
};

export default getCurrencyFromUuid;
