import { SideModal } from "shared";
import { CompanyMyType } from "types/CompanyTypes";
import "./TransactionsSideModal.scss";
import TransactionsTable from "./components/TransactionsTable";

interface TransactionsSideModalProps {
  isOpen: boolean;
  onClose: () => void;
  company: CompanyMyType | null;
}

const TransactionsSideModal: React.FC<TransactionsSideModalProps> = ({
  isOpen,
  onClose,
  company,
}) => {
  return (
    <SideModal
      isOpen={isOpen}
      onClose={onClose}
      title="Lista transakcji"
      subtitle={company?.name}
      className="company-info-modal wide"
    >
      <>
        <TransactionsTable company={company} />
      </>
    </SideModal>
  );
};

export default TransactionsSideModal;
