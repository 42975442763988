/* eslint-disable react-hooks/exhaustive-deps */
import Table from "rc-table";
import { mobileColumns, desktopColumns } from "./utils";
import { Pagination } from "shared";
import { isDesktop } from "utils";
import { useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useGetMe } from "hooks/auth";
import { ROLES_ENUM } from "enums";
import { AnalyzesTabsType } from "screens/analyzesPage/AnalyzesPage";
import { AnalyzeType } from "types/AnalyzeTypes";
import { MetaType } from "types/UtilsTypes";

interface AnalyzesTableType {
  page: number;
  setPage: (page: number) => void;
  tab: AnalyzesTabsType;
  data: { data: AnalyzeType[]; meta: MetaType } | undefined;
  onRowClick?: (
    record: AnalyzeType,
    e: React.MouseEvent<any, MouseEvent>
  ) => void;
  setModalManage: ({
    type,
    analyze,
  }: {
    type: string;
    analyze: AnalyzeType | null;
  }) => void;
}

const AnalyzesTable = ({
  setPage,
  page,
  tab,
  onRowClick,
  data,
  setModalManage,
}: AnalyzesTableType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: authorizedUser } = useGetMe();

  const handleChangePage = (e: number) => {
    setPage(e);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", e.toString());
    newSearchParams.set("role", tab);
    setSearchParams(newSearchParams);
  };
  const isAdmin = authorizedUser?.role === ROLES_ENUM.ADMIN;
  return (
    <div className="">
      <Tooltip
        place="top"
        className="tooltip tooltip--noShadow"
        id="company-tooltip"
      />

      <>
        <Table
          rowKey="id"
          className="companies-table"
          data={data?.data}
          onRow={(record: any) => ({
            onClick: (e) => onRowClick && isAdmin && onRowClick(record, e),
          })}
          columns={
            isDesktop()
              ? desktopColumns({
                  isAdmin,
                  setModalManage,
                })
              : mobileColumns({
                  isAdmin,
                  setModalManage,
                })
          }
        />
        {data?.meta && data?.meta?.lastPage > 1 && (
          <Pagination
            totalPages={data?.meta?.lastPage}
            currentPage={data?.meta?.currentPage || page}
            onPageChange={(e: any) => handleChangePage(e)}
          />
        )}
      </>
    </div>
  );
};

export default AnalyzesTable;
