import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { ActivateSubscription } from "types/SubscriptionTypes";

export const useActivateSubscription = (
    options?: UseMutationOptions<any, Error, ActivateSubscription>
) => {
    return useMutation({
        ...options,
        mutationKey: ["activateSubscription"],
        mutationFn: async (payment_method_uid) => {
            console.log(payment_method_uid)
            return axiosInstance
                .post("/subscriptions", {
                    payment_method_uid
                })
                .then((res) => res.data);
        },
    });
};

export default useActivateSubscription;
