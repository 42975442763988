import React from "react";
import "./PaymentMethodStep.scss";
import { Button } from "shared";
import { useStoreCustomer }  from "hooks/customers";
import { useStorePaymentMethod, useGetPaymentMethods } from "hooks/payments";
import { useActivateSubscription } from "hooks/subscriptions";


import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {CustomerType} from "../../../../../types/CustomerTypes";

interface Props {
  goToNextStep: () => void;
}

const inputStyles = {
  base: {
    color: "#111",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "#F6F6F6",
    border: "none",
    spacingGridRow: "0",
    spacingGridColumn: "0",
    display: "flex",
    "::placeholder": {
      color: "#808080",
    },
  },
  invalid: {
    color: "#e20d0d",
    iconColor: "#e20d0d",
  },
};

const PaymentMethodStep: React.FC<Props> = ({ goToNextStep }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { mutateAsync: storeCustomer } = useStoreCustomer();
  const { mutateAsync: addPaymentMethod } = useStorePaymentMethod();
  const { mutateAsync: activateSubscription } = useActivateSubscription();
  const { data: paymentMethods } = useGetPaymentMethods();

  const handleSubmit = async () => {
    if (sessionStorage.getItem("submitting")) return;
    sessionStorage.setItem("submitting", "true");
    // @ts-ignore
    const customer: CustomerType = await storeCustomer();
    // @ts-ignore
    const test = elements.getElement(CardNumberElement);
    // @ts-ignore
    const { token, error } = await stripe.createToken(test);
    if (error) throw error;

    const card = customer && await addPaymentMethod({stripePmToken: {id: token.id}});
    console.log(card.id)
    const subscription = card && await activateSubscription(card.id);
    subscription && goToNextStep();
  };

  return (
    <>
      {console.log(paymentMethods)}
      <h2 className="fullscreen-modal__header packet-modal__header">
        Metoda płatności
      </h2>
      <div className="payment-method-step">
        <p className="login-wrapper__subtitle">
          Podłącz swoją kartę płatniczą Lorem ipsum dolor sit amet consectetur.
          Feugiat
        </p>
        <div className="stripe-fields">
          <CardNumberElement
            options={{ style: inputStyles, placeholder: "Numer karty" }}
          />
          <CardExpiryElement
            options={{ style: inputStyles, placeholder: "Data ważności" }}
          />
        </div>
          <CardCvcElement options={{ style: inputStyles, placeholder: "CVV" }} />
        <div className="fullscreen-modal__buttons">
          <Button
            label="Dalej"
            className="button--black button--rounded"
            onClick={() => {
              handleSubmit();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentMethodStep;
