import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { AddCustomerType } from "types/CustomerTypes";

export const useStoreCustomer = (
  options?: UseMutationOptions<any, Error, AddCustomerType>
) => {
  return useMutation({
    ...options,
    mutationKey: ["storeCustomer"],
    mutationFn: async () => {
      return axiosInstance
        .post("/customers")
        .then((res) => res.data);
    },
  });
};

export default useStoreCustomer;
