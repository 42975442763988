import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { Toast } from "shared";
import { TOAST_TYPES_ENUM } from "enums";
import { queryClient } from "App";
import { SharesStoreType } from "types/SharesTypes";

export const useStoreShare = (
  options?: UseMutationOptions<any, Error, SharesStoreType>
) => {
  return useMutation({
    ...options,
    mutationKey: ["storeShares"],
    mutationFn: async (data) => {
      const formData = new FormData();
      formData.append("companyUuid", data.companyUuid);
      formData.append("userUuid", data.userUuid);
      formData.append("sharesCount", `${data.sharesCount}`);
      formData.append("sharesValue", `${data.sharesValue}`);
      formData.append("completedAt", `${data.completedAt}`);
      formData.append("invoice", data.invoice);

      return axiosInstance
        .post("/shares", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Trwa dodawanie udziałów...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "store-shares",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Udziały dodane pomyślnie.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "store-shares",
      });
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      queryClient.invalidateQueries({ queryKey: ["shares/company"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas dodawania udziałów.",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "store-shares",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useStoreShare;
