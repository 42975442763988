import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { AddressType } from "types/AddressTypes";

export default function useGetAddress(uuid?: string) {
  return useQuery({
    queryKey: ["address", uuid],
    queryFn: async () => {
      return axiosInstance
        .get(`address${uuid ? "?uuid=" + uuid : ""}`)
        .then((res) => res.data);
    },
    placeholderData: keepPreviousData,
    select: (data: AddressType) => data,
    retry: false,
  });
}
