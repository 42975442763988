/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from "react";
import "./calendar-input.scss";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { pl } from "date-fns/locale";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import { Button } from "shared";

interface CalendarInputProps {
  label?: string;
  type: string;
  inputOnFocus?: any;
  maxLength?: number;
  minDate?: any;
  maxDate?: any;
  withHours?: boolean;

  // formik;
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (data: string) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  onFocus: any;
  onBlur?: any;
}

const customPlLocale = {
  ...pl,
  localize: {
    ...pl.localize,
    day: (n: any, options: any) => {
      const dayNames = ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "Sb"];
      return dayNames[n];
    },
  },
};
registerLocale("custom-pl", customPlLocale);

const CalendarInput = ({
  label,
  errors = {},
  form,
  field,
  type,
  inputOnFocus,
  onBlur,
  maxLength,
  onChange,
  minDate,
  maxDate,
  withHours = false,
}: CalendarInputProps) => {
  const parseDate = (value: string | null) => {
    if (value) {
      const date = moment(value).toDate();
      return date;
    }
    return null;
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const [startDate, setStartDate] = useState(parseDate(field?.value));
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleSetTime = () =>
    withHours && field.value && field.value.split(" ");
  const [time, setTime] = useState(withHours && field?.value ? "" : "00:00");

  useEffect(() => {
    if (withHours) {
      setTime(handleSetTime()[1].slice(0, 5));
    }
  }, [withHours]);

  useEffect(() => {
    if (minDate) {
      const dateStartStr = form.values.dateStart;
      const dateEndStr = form.values.dateEnd;

      const dateStart = moment(dateStartStr, "YYYY-MM-DD HH:mm:ss");
      const dateEnd = moment(dateEndStr, "YYYY-MM-DD HH:mm:ss");

      if (dateEnd.isSameOrBefore(dateStart)) {
        const newDateEnd = dateStart.add(1, "minute");
        form.values.dateEnd = newDateEnd.format("YYYY-MM-DD HH:mm:ss");
        setStartDate(parseDate(newDateEnd.format("YYYY-MM-DD HH:mm:ss")));
        const newDate = newDateEnd.format("YYYY-MM-DD HH:mm:ss").split(" ");
        setTime(newDate[1].slice(0, 5));
      }
    }
    if (maxDate) {
      const dateStartStr = form.values.dateStart;
      const dateEndStr = form.values.dateEnd;

      const dateStart = moment(dateStartStr, "YYYY-MM-DD HH:mm:ss");
      const dateEnd = moment(dateEndStr, "YYYY-MM-DD HH:mm:ss");

      if (dateEnd.isSameOrAfter(dateStart)) {
        const newDateEnd = dateStart.add(1, "minute");
        form.values.dateEnd = newDateEnd.format("YYYY-MM-DD HH:mm:ss");
        setStartDate(parseDate(newDateEnd.format("YYYY-MM-DD HH:mm:ss")));
        const newDate = newDateEnd.format("YYYY-MM-DD HH:mm:ss").split(" ");
        setTime(newDate[1].slice(0, 5));
      }
    }
    field?.value && setStartDate(parseDate(field.value));
  }, [time, field.value]);

  const handleConfirmDate = () => {
    setIsDatePickerOpen(false);
    setStartDate(startDate || minDate);

    form.setFieldValue(
      field.name,
      startDate && withHours
        ? moment(startDate).format("YYYY-MM-DD HH:mm")
        : moment(startDate).format("YYYY-MM-DD HH:mm")
    );
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [time]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setIsDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCloseDate = () => {
    setStartDate(parseDate(field.value));
    setIsDatePickerOpen(false);
  };

  const ExampleCustomTimeInput = () => {
    const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = e.target.value;
      inputValue = inputValue.replace(/\D/g, "");
      if (inputValue.length > 2) {
        inputValue = `${inputValue.slice(0, 2)}:${inputValue.slice(2)}`;
      }
      if (inputValue.length > 5) {
        inputValue = inputValue.slice(0, 5);
      }
      setTime(inputValue);
      if (inputValue.length === 5) {
        const hours = parseInt(inputValue.slice(0, 2), 10);
        const minutes = parseInt(inputValue.slice(3, 5), 10);

        if (!isNaN(hours) && !isNaN(minutes)) {
          const newDate = new Date(startDate || new Date());
          newDate.setHours(hours);
          newDate.setMinutes(minutes);
          setStartDate(newDate);
          form.setFieldValue(field.name, newDate);
        }
      }
    };

    return (
      <input
        ref={inputRef}
        maxLength={5}
        pattern="[0-9]{0,2}:[0-5][0-9]"
        value={time}
        onChange={(e: any) => handleChangeTime(e)}
      />
    );
  };

  return (
    <div className="calendar-input__wrapper" ref={calendarRef}>
      <div className="calendar-input__content">
        {label && <label className="calendar-input__label">{label}</label>}
        <div
          className="calendar-input__icon"
          onClick={() => setIsDatePickerOpen(true)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
              stroke="#096EB5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div onClick={() => !isDatePickerOpen && setIsDatePickerOpen(true)}>
          <DatePicker
            readOnly
            showTimeInput={withHours}
            timeInputLabel="Godzina:"
            locale="custom-pl"
            selected={startDate}
            minDate={minDate}
            maxDate={maxDate}
            dateFormat={`${withHours ? "dd/MM/YYYY HH:mm" : "dd/MM/YYYY"}`}
            customTimeInput={<ExampleCustomTimeInput />}
            shouldCloseOnSelect={false}
            disabledKeyboardNavigation
            open={isDatePickerOpen}
            onChange={(date: any) => {
              setStartDate(date);
            }}
            renderCustomHeader={({
              monthDate,
              decreaseMonth,
              increaseMonth,
            }) => {
              return (
                <div className="react-datepicker__custom-head">
                  <button
                    type="button"
                    aria-label="Previous Month"
                    className={
                      "react-datepicker__navigation react-datepicker__navigation--previous"
                    }
                    onClick={decreaseMonth}
                  >
                    <span
                      className={
                        "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                      }
                    >
                      {"<"}
                    </span>
                  </button>
                  <span className="react-datepicker__current-month">
                    {monthDate.toLocaleString("pl", {
                      month: "long",
                    })}
                  </span>
                  <button
                    aria-label="Next Month"
                    type="button"
                    className={
                      "react-datepicker__navigation react-datepicker__navigation--next"
                    }
                    onClick={increaseMonth}
                  >
                    <span
                      className={
                        "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                      }
                    >
                      {">"}
                    </span>
                  </button>
                </div>
              );
            }}
          >
            <div className="react-datepicker__buttons">
              <Button
                onClick={() => {
                  handleCloseDate();
                }}
                label="Anuluj"
              />
              <Button label="Potwierdź" onClick={() => handleConfirmDate()} />
            </div>
          </DatePicker>
        </div>
      </div>
      {errors && form.touched && (
        <div className="calendar-input__error">
          {form.touched[field.name] && <span>{errors[field.name]}</span>}
        </div>
      )}
    </div>
  );
};

export default CalendarInput;
