import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { Toast } from "shared";
import { TOAST_TYPES_ENUM } from "enums";
import { queryClient } from "App";
import { UpdateAnalyzeType } from "types/AnalyzeTypes";

export const useUpdateAnalyze = (
  options?: UseMutationOptions<any, Error, UpdateAnalyzeType>
) => {
  return useMutation({
    ...options,
    mutationKey: ["updateAnalyze"],
    mutationFn: async (data) => {
      const formData = new FormData();
      if (data?.deadline) {
        formData.append("deadline", `${data?.deadline}`);
      }
      if (data?.status) {
        formData.append("status", `${data?.status}`);
      }
      if (data?.explanation) {
        formData.append("explanation", `${data?.explanation}`);
      }

      if (data.file) {
        formData.append("file", data.file);
      }

      return axiosInstance
        .put(`/analyzes/${data.uuid}`, formData)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Trwa edycja analizy...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "update-analyze",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Analiza edytowana pomyślnie.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "update-analyze",
      });
      queryClient.invalidateQueries({ queryKey: ["analyzes"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas edycji analizy.",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "update-analyze",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useUpdateAnalyze;
