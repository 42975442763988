import axiosInstance from "axiosConfig";
import { TOAST_TYPES_ENUM } from "enums";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { Toast } from "shared";
import { GetSmsCodeType } from "types/AuthTypes";

export const useGetSmsCode = (
  options?: UseMutationOptions<any, Error, GetSmsCodeType>
) => {
  return useMutation({
    mutationKey: ["getSmsCode"],
    mutationFn: async (credentials: GetSmsCodeType) => {
      return axiosInstance
        .get(`/auth/get-sms`, { params: credentials })
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Trwa wysyłanie kodu SMS...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "getSmsCode",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Wysłano kod SMS",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "getSmsCode",
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error: any, variables, context) => {
      if (error?.status === 500) {
        Toast({
          message: "Błąd serwera",
          type: TOAST_TYPES_ENUM.ERROR,
          id: "getSmsCode",
        });
      } else {
        Toast({
          message:
            "Nie udało się wysłać kodu, sprawdź poprawność numeru telefonu",
          type: TOAST_TYPES_ENUM.ERROR,
          id: "getSmsCode",
        });
      }
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
