import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { queryClient } from "App";
import axiosInstance from "axiosConfig";
import { TOAST_TYPES_ENUM } from "enums";
import { Toast } from "shared";
import { StoreAddressType } from "types/AddressTypes";

export const useStoreAddress = (
  options?: UseMutationOptions<any, Error, StoreAddressType>
) => {
  return useMutation({
    mutationKey: ["updateAddress"],
    mutationFn: async (values: StoreAddressType) => {
      return axiosInstance
        .put(`address${values.userUuid ? "/" + values.userUuid : ""}`, values)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Ładowanie...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "updateAddress",
      });

      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Zaktualizowano dane adresowe",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "updateAddress",
      });
      queryClient.prefetchQuery({
        queryKey: ["address", variables.userUuid ? variables.userUuid : null],
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },

    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas aktualizacji danych",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "updateAddress",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
