import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { Toast } from "shared";
import { TOAST_TYPES_ENUM } from "enums";
import { AddUserType } from "types/UsersTypes";

export const useCreateAccount = (
  options?: UseMutationOptions<any, Error, AddUserType>
) => {
  return useMutation({
    ...options,
    mutationKey: ["addUser"],
    mutationFn: async ({ name, phonePrefixUuid, phoneNumber, packet }) => {
      return axiosInstance
        .post("/users", {
          name,
          phonePrefixUuid,
          phoneNumber,
          package: packet,
        })
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Trwa dodawanie konta...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "add-user",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Pomyślnie dodano konto.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "add-user",
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas dodawania konta.",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "add-user",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useCreateAccount;
